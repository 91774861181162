import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, Flex, Heading, Text } from '@resideo/blueprint-react';
import { subscriptionData } from 'data/SubscriptionData';
import HomeTile from 'components/HomeTile';
import PageLoading from 'components/common/PageLoading';
import PageError from 'components/common/PageError';
import SeparatorLine from 'components/common/SeparatorLine';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionList, availableSubscriptionDetails } from 'redux/subscriptions/actions';
import { RootState } from 'redux/root-reducer';
import CompleteYourProfile from 'components/common/CompleteYourProfile';

const PageTitle = styled(Heading)`
    height: 50px;
    width: 441px;
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: 'URW_Din_CondBold';
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.91px;
    line-height: 80px;
`;

const ReviewAndAccessText = styled(Text)`
    height: 0.9375rem;
    width: 1030px;
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21.77px;
`;

const SubscribedServicesText = styled(Text)`
    height: 1.875rem;
    width: 325.88px;
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: Roboto;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 0.3125rem;
    margin-bottom: 0;
`;

const Unsubscribed = styled(Text)`
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: Roboto;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 16px;
`;

const Home = () => {
    const { t } = useTranslation();
    const [more, showMore] = useState(true);
    const [showMoreIndex, setShowMoreIndex] = useState(-1);
    const dispatch = useDispatch();
    const [contentLoading, setContentLoading] = useState(true);
    const isLoading = useSelector(
        (state: RootState) => state.subscriptionListData.fetchingSubscriptionSuccess,
    );
    const error = useSelector(
        (state: RootState) => state.subscriptionListData.fetchingSubscriptionError,
    );
    const subscriptionListData = useSelector(
        (state: RootState) => state.subscriptionListData.fetchingSubscriptionList,
    );
    const availableServices: any = useSelector(
        (state: RootState) => state.subscriptionListData.availableSubscription,
    );

    const { isB2bUserPresent, creatingB2bUser }: any = useSelector(
        (state: RootState) => state.b2bUserDetails,
    );

    const userDetails: any = useSelector((state: RootState) => state.authentication.userDetails);
    const { pending } = userDetails;


    let s = [];
    let u = [];

    useEffect(() => {
        if (isB2bUserPresent) {
            dispatch(getSubscriptionList());
            dispatch(availableSubscriptionDetails());
        }
    }, [isB2bUserPresent]);

    useEffect(() => {
        const loadingContent = creatingB2bUser || pending > 0 || isLoading;
        setContentLoading(loadingContent);
    }, [creatingB2bUser, isLoading, pending]);

    const dynamicSort = (property) => (a, b) => {
        return a[property].localeCompare(b[property]);
    };

    if (subscriptionListData) {
        const { subscriptions, unsubscribed } = subscriptionData(
            subscriptionListData,
            more,
            showMoreIndex,
        );

        s = subscriptions;
        u = unsubscribed;
    }

    const isB2BPresent = isB2bUserPresent ? (
        <Box
            as='main'
            data-test-homepage
            paddingTop='small'
            paddingBottom={['small', 'large']}
            paddingX={['small', 'large']}>
            <Flex alignItems='flex-start' flexDirection='column'>
                <Heading
                    data-test-page-title
                    paddingTop='small'
                    fontWeight='bold'
                    fontSize='xxxLarge'
                    fontFamily='URW_Din_CondBold'
                    letterSpacing='1px'
                    color='black'
                    marginBottom='small'>
                    {t('menu.servicesAndSubscriptions')}
                </Heading>
                <Text data-test-page-description fontSize='medium' color='black'>
                    {t('home.title')}
                </Text>
            </Flex>

            {/* <PageTitle fontSize={['large', 'xxxLarge']} marginBottom='medium'>
        {t('menu.servicesAndSubscriptions')}
      </PageTitle>
      <ReviewAndAccessText>{t('home.title')}</ReviewAndAccessText> */}
            <SeparatorLine m='2.0625rem 0' />

            {s.length > 0 && (
                <>
                    <SubscribedServicesText>
                        {t('mybusiness.servicesAndSubscriptions.subscribedServices')}
                    </SubscribedServicesText>
                    <Flex flexWrap='wrap' width={1}>
                        {s.map((d, i) => (
                            <HomeTile
                                data-test-home-tile
                                key={i}
                                i={i}
                                d={d}
                                more={more}
                                showMore={showMore}
                                setShowMoreIndex={setShowMoreIndex}
                            />
                        ))}
                    </Flex>
                </>
            )}

            {u.length > 0 && (
                <>
                    <Unsubscribed>
                        {t('mybusiness.servicesAndSubscriptions.unsubscribedServices')}
                    </Unsubscribed>
                    <Flex flexWrap='wrap'>
                        {u.map((d, i) => (
                            <HomeTile
                                key={i}
                                i={i}
                                d={d}
                                more={more}
                                showMore={showMore}
                                setShowMoreIndex={setShowMoreIndex}
                            />
                        ))}
                    </Flex>
                </>
            )}
            {s.length === 0 && u.length === 0 && (
                <>
                    <SubscribedServicesText>{t('mybusiness.servicesAndSubscriptions.availableServices')}</SubscribedServicesText>
                    <Flex flexWrap='wrap' width={1}>
                        {availableServices &&
                            availableServices.products &&
                            availableServices.products.map((data, index) =>
                                <HomeTile
                                    key={index}
                                    i={0}
                                    d={{
                                        name: data.name,
                                        status: 'subscribe',
                                        productCode: data.code,
                                        desc: data.description
                                        // !more || showMoreIndex !== 0
                                        //             ? t('mybusiness.description.brandingMore')
                                        //             : {
                                        //                   text: t('mybusiness.description.brandingLess'),
                                        //                   text1: t('mybusiness.description.brandingLess1'),
                                        //                   text2: t('mybusiness.description.brandingLess2'),
                                        //               },
                                    }}
                                    more={more}
                                    showMore={showMore}
                                    setShowMoreIndex={setShowMoreIndex}
                                />
                            )}
                    </Flex>
                </>
            )}
        </Box>
    ) : (
        <CompleteYourProfile />
    );

    return contentLoading ? (
        <PageLoading />
    ) : error && isB2bUserPresent ? (
        <PageError />
    ) : (
        isB2BPresent
    );
};

export default Home;
