import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { b2bProUserUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import { createB2BUserSuccess, createB2BUserFail } from './action';

function* createB2BUserDetails({ payload }: any): any {
    const { b2BCustomerWsDTO } = payload;
    try {
        const url = b2bProUserUrl + `b2bprousers`;
        const response = yield call(
            api,
            url,
            httpRequest.POST,
            b2BCustomerWsDTO,
            0,
            0,
            false,
            true,
        );
        yield put(createB2BUserSuccess(response));
    } catch (Error: any) {
        yield put(createB2BUserFail(Error));
    }
}

function* createB2BUserWatcher() {
    yield takeLatest(types.CREATE_B2B_PRO_USER, createB2BUserDetails);
}

export default function* b2bUserSaga() {
    yield all([createB2BUserWatcher()]);
}
