import React, { useContext, useState, useEffect } from 'react';
import { Box, Image, Text, Heading, Select, Flex } from '@resideo/blueprint-react';
import { homeOwnerZoomedLogo, resideoBlackLogo } from '../../assets/images/';
import styled from 'styled-components';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { advancedPDPImage, basicServicePDP } from '../../assets/images/';
import proProductPDP from '../../assets/images/productDetails/proProductPDP.jpg';
import supportPDP from '../../assets/images/productDetails/supportPDP.jpg';
import { convertMoneyAmount } from '../../routes/Products/Cart';
import BreadCrumb from './BreadCrumb';
import { SideBySideMagnifier } from 'react-image-magnifiers';
import FullScreenImg from './FullScreenImg';
import GlobalStyle from '../../components/global/GlobalStyle';
import { productSubscriptionArray } from '../../data/SelectPlanData';
import { useTranslation } from 'react-i18next';
import HABCarousel from './HABCarousel';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetails } from '../../redux/productdetails/actions';
import { addProductInCart, getCardDetails } from '../../redux/cart/actions';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { RootState } from '../../redux/root-reducer';
import LoadingDots from '../common/LoadingDots';
import ReactStars from 'react-rating-stars-component';
import BreadCrumbPdp from './BreadCrumbPdp';
import { signIn, signOut } from '../../config/Auth';

const StyledUppercaseHeading = styled(Heading)`
    text-transform: uppercase;
    //height: 37px;
    color: #032a3b;
    font-family: 'URW_Din_CondBold';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.75px;
    line-height: 30px;
    margin-top: 10px;
    @media (max-width: 400px) {
        font-size: 24px;
    }
`;

const StyledBodySubHeading = styled(Heading)`
    text-transform: uppercase;
    color: #032a3b;
    font-family: Roboto;
    font-size: 14px;
    margin-top: 4px;
`;

const BoxRight = styled(Flex)`
    display: flex;
    align-items: center;
    padding: 40px 0 60px 0;
    @media (max-width: 768px) {
        /* padding: 40px 0 100px 0; */
        /* margin: 0; */
        /* padding: 0; */
    }
`;

const ImageRecideo = styled(Image)`
    margin-top: 25px;
    width: 105px;
    height: 23px;
`;

const TextDescTitle = styled(Text)`
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 4px;
    @media (max-width: 768px) {
        margin-top: -30px;
    }
`;

const TextProductDesc = styled(Text)`
    font-size: 1rem;
    padding-bottom: large;
    line-height: 24px;
    /* width: 611px; */
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 20px;
`;

const HeadingSelectPlan = styled(Heading)`
    margin-top: 30px;
    font-size: 14px;
    font-weight: 500;
`;

const StyledSelect = styled(Select)`
    border-radius: 0px;
    /* these styles would not apply without this */
    border: none !important;
    box-shadow: none !important;
    text-overflow: ellipsis;
`;

const LinkAddToCart = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    padding: 1rem 2rem;
    width: 180px;
    height: 18px;
    text-align: center;
    color: #ffffff;
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    @media (max-width: 768px) {
        margin-top: 30px;
        width: 120px;
    }
`;

const TextPro = styled.div`
    border-left: 1px solid;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-left: 20px;
    height: 32px;
    line-height: 32px;
    padding-left: 20px;
`;

const ProductTitle = styled.div`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

const ProductTitleMobile = styled.div`
    margin: 0 30px;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const BoxPriceCart = styled(Box)`
    padding-top: 45px;
    align-items: flex-start;
`;

const BoxStarRating = styled(Box)`
    display: flex;
    padding-top: 20px;
    align-items: center;
`;

const RatingText = styled.div`
    display: flex;
    align-items: center;
    padding-right: 0.1em;
    color: #166a96;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: none;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    margin: 0px 10px;
`;
const HorizontalLine = styled.span`
    padding-right: 0.1em;
    color: #ccc;
    font-size: 16px;
`;

const RatingSummary = styled.div`
    white-space: nowrap;
    color: #166a96;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: none;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    margin-left: 10px;
`;
const LogInToViewPrice = styled.div`
    background: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    padding: 1rem 2rem;
    width: 180px;
    height: 18px;
    text-align: center;
    color: #ffffff;
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 6rem;
    margin-left: 16rem;
    @media (max-width: 768px) {
        margin-top: 30px;
        width: 120px;
    }
`;
interface ProductDescriptionProps {
    auth: boolean;
    service: string;
    setService: (arg0: string) => void;
    productDetails: ProductDetailsProps;
}
type ProductDetailsProps = {
    code?: string;
    manufacturer?: string;
    name?: string;
    description?: any;
    summary?: string;
    images?: any;
    baseOptions?: BaseOption[];
    price: Price;
    stock?: Stock3;
    averageRating?: string;
    numberOfReviews?: string;
};
type BaseOption = {
    length: number;
    options?: Option[];
    selected?: SelectedProps;
};
type Option = {
    code: string;
    name: string;
    stock: Stock;
    url: string;
    variantOptionQualifiers: any[];
};
type Price = {
    currencyIso: string;
    formattedValue: string;
    priceType: string;
    value: number;
};
type Stock3 = {
    isValueRounded: boolean;
    stockLevel: number;
    stockLevelStatus: string;
};
type Stock = {
    isValueRounded: boolean;
    stockLevel: number;
};
type SelectedProps = {
    code?: string;
};

const OverviewList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding-left: 40px;
    margin-top: 18px;
    margin-bottom: 0px;
    flex: 1;
    & li {
        flex-shrink: 1;
        flex-basis: auto;
        flex: 100%;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.18px;
        line-height: 30px;
        @media (max-width: 1024px) {
            flex: 100%;
        }
    }
`;

const ProductDescription = ({
    auth,
    service,
    setService,
    productDetails,
}: ProductDescriptionProps) => {
    const history = useHistory();
    const [fullScreenImage, setFullScreenImage] = useState(false);
    const { path } = useRouteMatch();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const isLoading = useSelector((state: RootState) => state.cartDetails.addProductInCart);
    const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleAddToCart = async () => {
        let dataToSend = {
            orderEntries: [
                {
                    product: {
                        code: productDetails.code,
                    },
                    quantity: 1,
                },
            ],
        };

        hookDispatch(addProductInCart({ body: dataToSend }))
            .then((response) => {
                dispatch(getCardDetails());
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    const getImage = (service): string => {
        return service === 'HAB'
            ? homeOwnerZoomedLogo
            : service === 'BASIC_MONTHLY' || service === 'BASIC_ANNUALLY'
            ? basicServicePDP
            : service === 'ADVANCED_MONTHLY' || service === 'ADVANCED_ANNUALLY'
            ? advancedPDPImage
            : service === 'PRO'
            ? proProductPDP
            : service === 'HAB'
            ? proProductPDP
            : supportPDP;
    };

    const renderProductTitle = () => {
        return (
            <>
                <ImageRecideo src={resideoBlackLogo} alt='resideo' />
                <StyledUppercaseHeading data-test-pdp-product-title>
                    {productDetails?.name}
                </StyledUppercaseHeading>

                <StyledBodySubHeading>{productDetails?.code}</StyledBodySubHeading>
            </>
        );
    };

    const getProducts = async (productID) => {
        await dispatch(getProductDetails(productID));
    };

    return (
        <>
            <GlobalStyle hideScrollBar={fullScreenImage} />
            {fullScreenImage && (
                <FullScreenImg service={service} setFullScreenImage={setFullScreenImage} />
            )}
            <Flex flexDirection={['column', 'column', 'row', 'row']} marginX={[0, 0, 0, 0]}>
                <Flex flexDirection='column' width={['100%', '100%', '100%', '50%']}>
                    <ProductTitleMobile>{renderProductTitle()}</ProductTitleMobile>

                    {service === 'PRO' ? (
                        <HABCarousel images={productDetails.images} />
                    ) : (
                        <Box
                            onClick={() => setFullScreenImage(true)}
                            maxWidth={['265px', '265px', '400px', '400px']}
                            maxHeight={['265px', '265px', '400px', '400px']}
                            style={{ margin: '60px auto' }}
                            paddingX={[0, 0, '35px', '35px', 0]}>
                            <SideBySideMagnifier
                                imageSrc={getImage(service)}
                                imageAlt='img-magnifier'
                                cursorStyle='zoom-in'
                                fillAlignTop={true}
                                fillAvailableSpace={false}
                                overlayBackgroundColor='#176996'
                            />
                        </Box>
                    )}
                </Flex>
                <BoxRight
                    height={['auto', 'auto', '600px', '600px']}
                    backgroundColor='#B2ADA6'
                    flexDirection='column'
                    width={['100%', '100%', '75%', '50%']}>
                    <Box minWidth='80%'>
                        <ProductTitle>
                            <BreadCrumbPdp data={productDetails} />
                            {renderProductTitle()}
                        </ProductTitle>

                        {productDetails && productDetails.averageRating && (
                            <>
                                <BoxStarRating
                                    display={'flex'}
                                    justifyContent={'flex-start'}
                                    flexDirection={['column', 'row', 'column', 'row']}
                                    alignSelf={'flex-end'}>
                                    <Flex
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        data-test-pdp-star-rating>
                                        <ReactStars
                                            count={5}
                                            value={productDetails.averageRating}
                                            size={36}
                                            edit={false}
                                            isHalf={true}
                                            activeColor='#166A96'
                                        />
                                        <RatingText>{productDetails?.averageRating}</RatingText>
                                        <HorizontalLine>|</HorizontalLine>
                                    </Flex>
                                    <RatingSummary>
                                        {productDetails?.numberOfReviews} Review
                                    </RatingSummary>
                                </BoxStarRating>
                            </>
                        )}

                        <TextProductDesc data-test-pdp-product-description>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: productDetails.description
                                        ? productDetails.description
                                        : productDetails.summary,
                                }}
                            />
                        </TextProductDesc>

                        {productDetails &&
                            productDetails.baseOptions &&
                            productDetails.baseOptions.length > 0 && (
                                <>
                                    <HeadingSelectPlan
                                        data-test-pdp-select-plan
                                        paddingBottom='small'>
                                        {'Select a Model'}
                                    </HeadingSelectPlan>

                                    <StyledSelect
                                        name='service'
                                        onChange={(e: any) => getProducts(e.target.value)}
                                        defaultValue={
                                            productDetails?.baseOptions[0].selected?.code
                                        }>
                                        {productDetails &&
                                            productDetails.baseOptions &&
                                            productDetails?.baseOptions[0]?.options?.map(
                                                (obj, i) => (
                                                    <option value={obj.code} key={i}>
                                                        {obj.name}
                                                    </option>
                                                ),
                                            )}
                                    </StyledSelect>
                                </>
                            )}
                        {isAuthenticated ? (
                            <BoxPriceCart
                                display={'flex'}
                                justifyContent={'space-between'}
                                flexDirection={['column', 'row', 'column', 'row']}
                                alignSelf={'flex-end'}>
                                <Flex
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    data-test-pdp-product-plan-basic-monthly-amount>
                                    <Heading
                                        fontWeight='500'
                                        paddingRight='xSmall'
                                        fontSize='1.75rem'
                                        letterSpacing='normal'
                                        color='header.secondaryLinkActive'>
                                        {convertMoneyAmount(
                                            {
                                                amount: productDetails?.price?.value,
                                                currencyCode: productDetails?.price?.currencyIso,
                                            },
                                            'en-us',
                                        )}
                                    </Heading>

                                    <TextPro color='header.secondaryLinkActive'>
                                        {productDetails?.stock?.stockLevelStatus}
                                    </TextPro>
                                </Flex>
                                <LinkAddToCart
                                    data-test-pdp-subscribe-btn
                                    onClick={() => {
                                        handleAddToCart();
                                    }}>
                                    {isLoading ? (
                                        <LoadingDots />
                                    ) : (
                                        t('productDescription.addToCartCapital')
                                    )}
                                </LinkAddToCart>
                            </BoxPriceCart>
                        ) : (
                            <LogInToViewPrice onClick={() => signIn()}>
                                {' '}
                                LOGIN TO VIEW PRICING{' '}
                            </LogInToViewPrice>
                        )}
                    </Box>
                </BoxRight>
            </Flex>
        </>
    );
};

export default ProductDescription;
