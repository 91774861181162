import React, { useEffect, useState } from 'react';
import { Box, Heading, Link, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { TableHeader } from 'components/common/Styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ENABLE_MOCKS } from 'config';
import { Title } from 'components/common/Title';
import { convertMoneyAmount } from 'routes/ServicesAndSubscriptions/Cart';

const StyledHeading = styled(Heading)`
  cursor: pointer;
  white-space: nowrap;
  letter-spacing: 0px;
  line-height: 1.188rem;
`;

const TableData = styled.td`
  font-size: ${({ theme }) => theme.space.large};
  color: #000;
`;

const TableDataText = styled(Text)`
  color: ${({ theme }) => theme.colors.textHeading};
  font-size: 1rem;
  font-family: 'Roboto';
`;

export const showDesc = (sku) => {
    if (ENABLE_MOCKS) {
        switch (sku) {
            case 'ACAdvancedAnnualDevice':
                return 'AirCycle Advanced Annual Device';
            case 'ACAdvancedMonthlyDevice':
                return 'AirCycle Advanced Monthly Device';
            case 'ACBasicAnnualDevice':
                return 'AirCycle Basic Annual Device';
            case 'ACBasicMonthlyDevice':
                return 'AirCycle Basic Monthly Device';
            case 'ACBasicMonthly':
                return 'AirCycle Basic Monthly';
            case 'ACBasicAnnual':
                return 'AirCycle Basic Annual';
            case 'ACAdvancedMonthly':
                return 'AirCycle Advanced Monthly';
            case 'ACAdvancedAnnual':
                return 'AirCycle Advanced Annual';
            case 'ACDevice':
                return 'AirCycle Pro Device';
            case 'HH05':
                return 'AirCycle Device Refresh';
        }
    } else {
        switch (sku) {
            case '6b03408e-9f53-492d-bd8a-54703f21442d':
                return 'AirCycle Advanced Monthly';
            case 'aab2d927-d0b8-4063-a406-a70fb310ba6e':
                return 'AirCycle Advanced Annual';
            case 'cb57568e-56ab-4876-8f9c-fdb8a2916355':
                return 'AirCycle Basic Monthly';
            case 'ae181ac0-0df1-4124-9e9b-24dfe1bb3eb6':
                return 'AirCycle Basic Annual';
            case 'IAQMNXXSA1000-U':
                return 'AirCycle Pro Device';
            case 'HH01':
                return 'AirCycle Basic Monthly Device';
            case 'HH02':
                return 'AirCycle Basic Annual Device';
            case 'HH03':
                return 'AirCycle Advanced Monthly Device';
            case 'HH04':
                return 'AirCycle Advanced Annual Device';
            case 'HH05':
                return 'AirCycle Device Refresh';
            case 'HH06':
                return 'Homeowner App Branding';
            // keep the old SKU because there are orders/checkouts in DR that still
            // have this SKU.
            case 'IAQMNXXSA1000':
                return 'AirCycle Pro Device';
        }
    }
};

const BillingHistory = ({ billingHistory }) => {
    const { t } = useTranslation();
    const [billingHistoryFullView, setBillingHistoryFullView] = useState(false);

    return (
        <Box paddingTop='large'>
            {billingHistory?.length > 0 && (
                <>
                    <Title data-test-billing-history-title>
                        {t('manageSubscriptions.billingHistory.title')}
                    </Title>
                    <div className='hscroll'>
                        <table className='table-responsive'>
                            <thead>
                                <tr>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.billingHistory.table.1')}
                                    </TableHeader>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.billingHistory.table.2')}
                                    </TableHeader>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.billingHistory.table.3')}
                                    </TableHeader>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.billingHistory.table.4')}
                                    </TableHeader>
                                    <TableHeader style={{ textAlign: 'right', fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.billingHistory.table.5')}
                                    </TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {billingHistory?.map((obj, i) => {
                                    return (
                                        <tr key={i} className='tr-border-bottom'>
                                            <TableData data-test-billing-history-date>
                                                <TableDataText>
                                                    {obj?.billingDate ? (
                                                        moment(obj?.billingDate).format(
                                                            'MMM D, YYYY',
                                                        )
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </TableDataText>
                                            </TableData>
                                            <TableData data-test-billing-history-description>
                                                <TableDataText key={i}>
                                                    {obj?.description}
                                                </TableDataText>
                                            </TableData>
                                            <TableData data-test-billing-history-service-period>
                                                {obj?.servicePeriod}
                                            </TableData>
                                            <TableData data-test-billing-history-service-total>
                                                <TableDataText color='textHeading'>
                                                    {obj?.total
                                                        ? convertMoneyAmount(
                                                              {
                                                                  amount: obj?.amount ?? 0,
                                                                  currencyCode: 'USD',
                                                              },
                                                              'en-us',
                                                          )
                                                        : ''}
                                                </TableDataText>
                                            </TableData>
                                            <td style={{ textAlign: 'right', textDecoration: 'none' }}>
                                                <Link
                                                    data-test-billing-history-invoice-number
                                                    href={`${
                                                        process.env.PUBLIC_URL ?? ''
                                                    }/invoice/${obj?.invoiceNumber}`}
                                                    fontSize='medium'
                                                    color='primary'
                                                    fontWeight='500'
                                                    style={{ textAlign: 'right', textDecoration: 'none' }}
                                                    target='_new'
                                                    key={i}>
                                                    {obj?.invoiceNumber}
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {billingHistory?.length > 3 && (
                                    <tr>
                                        <td>
                                            <StyledHeading
                                                fontWeight='medium'
                                                fontSize='medium'
                                                color='primary'
                                                onClick={() =>
                                                    setBillingHistoryFullView(
                                                        !billingHistoryFullView,
                                                    )
                                                }>
                                                {billingHistoryFullView
                                                    ? t('manageSubscriptions.billingHistory.less')
                                                    : t('manageSubscriptions.billingHistory.all')}
                                            </StyledHeading>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </Box>
    );
};

export default BillingHistory;
