import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Text, Image, Link } from '@resideo/blueprint-react';
import Page from 'components/common/Page';
import styled from 'styled-components';
import { whiteWarningIcon } from 'assets/images';


const ErrorBox = styled(Box)`
    display: flex;
    padding: 1.875rem;
    align-items: center;
`;

const PageNotFound: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const isDevelopEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';

    return (
        <Page as='main' data-test-page-not-found>
            <ErrorBox backgroundColor='redAlertError'>
                <Image
                    src={whiteWarningIcon}
                    minHeight='0.875rem'
                    minWidth='0.875rem'
                    alt='error'
                />
                <Text
                    marginLeft='0.625rem'
                    alignItems='center'
                    color='white'
                    fontWeight='bold'
                    fontSize='0.875rem'>
                    {t('cart.completeProfileError1')}
                    <Link
                        paddingX='0.3rem'
                        fontSize='0.875rem'
                        color='header.primaryLinkActive'
                        target='blank'
                        href={
                            isDevelopEnvironment
                                ? 'https://develop--resideo-pro.netlify.app/onboarding/profile'
                                : `${window.location.origin}/onboarding/profile`
                        }>
                        {t('cart.completeProfileError2')}
                    </Link>
                    {t('cart.completeProfileError3')}
                </Text>
            </ErrorBox>
        </Page>
    );
};

export default PageNotFound;
