import React, { FC, useEffect, useMemo } from 'react';
import { History } from 'history';
import Router from './Router';
import Routes from './routes';
import { ThemeProvider } from '@resideo/blueprint-react';
import colors from './themes/light';
import ErrorBoundary from './components/common/ErrorBoundary';
import { PageError } from './components/common/PageError';
import { MobileLeftNavProvider } from './context/MobileLeftNav';
import { Environment, RecordSource, Store, Network } from 'relay-runtime';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import GlobalStyle from './components/global/GlobalStyle';
import './styles/fonts.css';
import './styles/app.css';
import { useTranslation } from 'react-i18next';
import { local } from './utils/storage';
import Auth0ProviderWithHistory from './config/auth0Provider';
import { useDispatch } from 'react-redux';
import useScript from 'hooks/useScript';
import { fetchQueryFactory } from 'redux/graphql/authentication/relayfactory';

/** The below useEffect and dispatch are just samples of how to use our SAGA */

const App: FC<{ history?: History }> = ({ history }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const prefLocale = local.getItem('pref-locale');
        if (prefLocale && prefLocale !== 'en') {
            i18n.changeLanguage(prefLocale);
        }
    }, [i18n]);

    useScript('https://js.digitalriver.com/v1/DigitalRiver.js', true, false);
    const url = process.env.REACT_APP_GATEWAY_UR || '';

    const relayEnvironment: any = useMemo(() => {
        return (
          new Environment({
            network: Network.create(fetchQueryFactory({ url })),
            store: new Store(new RecordSource()),
          })
        );
      }, [url]);

    return (
        <Auth0ProviderWithHistory>
            <RelayEnvironmentProvider environment={relayEnvironment}>
                <Router history={history}>
                    <ThemeProvider theme={colors}>
                        <ErrorBoundary handler={PageError}>
                            <MobileLeftNavProvider>
                                <GlobalStyle />
                                <Routes />
                            </MobileLeftNavProvider>
                        </ErrorBoundary>
                    </ThemeProvider>
                </Router>
            </RelayEnvironmentProvider>
        </Auth0ProviderWithHistory>
    );
};

export default App;
