import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Flex, Heading, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SeparatorLine from '../../../components/common/SeparatorLine';
import { convertMoneyAmount } from '../../../routes/Products/Cart';
import RecaliberationInstructionsModal from '../../../components/cart/order/RecaliberationInstructionsModal';
import { ReactComponent as PaypalIcon } from '../../../assets/images/cart/icon_Paypal.svg';
import CustomPopup from '../../../components/common/customPopup/customPopup.style';
import CartItem from '../../../components/cart/CartItem';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';
import PageLoading from '../../../components/common/PageLoading';
import {
    CreditCardTypeImage,
    getSmallerCCimg,
} from '../../../components/cart/reviewOrder/PaymentMethod';
import { maskPhoneNumber } from '../../../utils/maskPhoneNumber';
import { getFullCountryByCode } from '../../../config/countries';
import { getOrderInformation } from '../../../redux/orderSummary/actions';
import { RootState } from '../../../redux/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import PageError from 'components/common/PageError';

const Container = styled.div`
    margin-top: -1rem;
`;
const PageTitle = styled.div`
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.textLight3};
    font-family: 'URW_Din_CondBold';
    font-size: 2.75rem;
    line-height: 5rem;
    letter-spacing: 1.47px;
    @media (max-width: 768px) {
        height: 50px;
        width: 362px;
    }
`;

const EmailLabelStrong = styled.span`
    @media (max-width: 768px) {
        font-family: 'Roboto';
        font-weight: 500;
        width: 364px;
    }
`;

const Print = styled(Link)`
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.cartLink};
`;

const FlexBox = styled(Flex)`
    @media (max-width: 768px) {
        border-radius: 15px;
    }
`;

const HeadingBox = styled(Heading)`
    margin-bottom: 1.063rem;
    font-family: 'Roboto';
    font-weight: bold;
`;
const TextBox = styled(Text)`
    line-height: 24px;
`;

const LabelStrong = styled.span`
    font-family: 'Roboto';
    font-weight: 700;
`;

const LabelValue = styled.span`
    margin-left: 0.75rem;
`;

const ThankYou: FC<any> = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(
        (state: RootState) => state.orderSummary.fetchingOrderInformation,
    );
    const isError = useSelector(
        (state: RootState) => state.orderSummary.fetchingOrderInformationError,
    );
    const orderDetails = useSelector((state: RootState) => state.orderSummary.orders);

    const params = useParams() as any;
    const orderId = params?.id;
    const [order, setOrder] = useState<any>(null);

    const { t } = useTranslation();
    const [hasSubmittedDevice, setHasSubmittedDevice] = useState(false);
    const [openInstructionsModal, setOpenInstructionsModal] = useState(false);
    const closeAddAddressModal = () => setOpenInstructionsModal(false);
    const today = moment(order?.created).format('MMM DD, YYYY');
    const taxData = JSON.parse(`${localStorage.getItem('taxExemptionApplication')}`);
    const lastFourNums = order?.drpaymentInfo?.drcclastFourDigits;

    const hasProduct: any =
        order &&
        order?.unconsignedEntries &&
        order?.unconsignedEntries?.filter((x) => x.product.itemType?.toLowerCase() === 'product')
            .length > 0;

    const invoiceData = useCallback(async () => {
        dispatch(getOrderInformation(orderId));
    }, [orderId]);

    useEffect(() => {
        if (orderDetails && Object.keys(orderDetails).length === 0) {
            invoiceData();
        }
    }, [orderId]);

    useEffect(() => {
        setOrder(orderDetails);
    }, [orderDetails]);

    return isLoading ? (
        <PageLoading />
    ) : isError ? (
        <PageError />
    ) : (
        <>
            {openInstructionsModal && (
                <CustomPopup open={openInstructionsModal} closeOnDocumentClick={false}>
                    <RecaliberationInstructionsModal
                        close={closeAddAddressModal}
                        hasSubmittedDevice={hasSubmittedDevice}
                        setHasSubmittedDevice={setHasSubmittedDevice}
                    />
                </CustomPopup>
            )}
            <Container>
                <Box padding={['medium', 'medium', 'large', 'large']} paddingTop={'initial'}>
                    <PageTitle>{t('cart.orderComplete.thankyou')}</PageTitle>
                    <Text color='header.secondaryLinkActive' fontFamily='Roboto'>
                        {t('cart.orderComplete.orderPlaced')}{' '}
                        <EmailLabelStrong>{order?.email}</EmailLabelStrong>
                    </Text>
                    <SeparatorLine />
                    <Flex paddingBottom='small' display={['', '', 'none', 'none']}>
                        <Print
                            to={`/cart/print-friendly-view/${orderId}`}
                            color='primary'
                            target='_blank'>
                            {t('cart.orderComplete.printFriendlyView')}
                        </Print>
                    </Flex>
                    <Flex justifyContent='space-between' alignItems='flex-end'>
                        <Flex flexDirection='column'>
                            <Text color='header.secondaryLinkActive'>
                                <LabelStrong>{t('cart.order.orderNumber')}</LabelStrong>
                                <LabelValue>{order?.code}</LabelValue>
                            </Text>
                            <Text paddingY='small' color='header.secondaryLinkActive'>
                                <LabelStrong>{t('cart.order.date')}</LabelStrong>
                                <LabelValue>{today}</LabelValue>
                            </Text>
                            <Text color='header.secondaryLinkActive'>
                                <LabelStrong>{t('cart.order.orderStatus')}</LabelStrong>
                                <LabelValue>{t('cart.order.completed')}</LabelValue>
                            </Text>
                        </Flex>
                        <Flex display={['none', 'none', 'flex', 'flex']}>
                            <Print
                                to={`/cart/print-friendly-view/${orderId}`}
                                color='primary'
                                target='_blank'>
                                {t('cart.orderComplete.printFriendlyView')}
                            </Print>
                        </Flex>
                    </Flex>
                    <FlexBox
                        justifyContent={'space-between'}
                        flexWrap='wrap'
                        padding='large'
                        backgroundColor='badge.normal'
                        style={{ margin: '2rem 0' }}>
                        <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                            <HeadingBox color='header.secondaryLinkActive'>
                                {t('cart.order.contactInformation')}
                            </HeadingBox>
                            <TextBox color='header.secondaryLinkActive'>{order?.email}</TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {maskPhoneNumber(order?.phoneNumber ?? order?.phoneNumber, 'Order')}
                            </TextBox>
                        </Flex>
                        {hasProduct && (
                            <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                                <HeadingBox color='header.secondaryLinkActive'>
                                    {t('cart.order.shippingAddress')}
                                </HeadingBox>
                                <TextBox color='header.secondaryLinkActive'>
                                    {order?.deliveryAddress?.firstName}{' '}
                                    {order?.deliveryAddress?.lastName}
                                </TextBox>
                                <TextBox color='header.secondaryLinkActive'>
                                    {order?.deliveryAddress?.line1}
                                </TextBox>
                                <TextBox color='header.secondaryLinkActive'>
                                    {order?.deliveryAddress?.region?.isocode}
                                </TextBox>
                                <TextBox color='header.secondaryLinkActive'>
                                    {order?.deliveryAddress?.postalCode}
                                </TextBox>
                                <TextBox color='header.secondaryLinkActive'>
                                    {t(
                                        getFullCountryByCode(
                                            order?.deliveryAddress?.country?.isocode,
                                        ),
                                    )}
                                </TextBox>
                            </Flex>
                        )}
                        <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                            <HeadingBox color='header.secondaryLinkActive'>
                                {t('cart.order.billingAddress')}
                            </HeadingBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {order?.drpaymentInfo?.billingAddress?.firstName}{' '}
                                {order?.drpaymentInfo?.billingAddress?.lastName}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {order?.drpaymentInfo?.billingAddress?.line1}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {order?.drpaymentInfo?.billingAddress?.town}
                                {', '}
                                {order?.drpaymentInfo?.billingAddress?.isocode}{' '}
                                {order?.drpaymentInfo?.billingAddress?.postalCode}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {t(
                                    getFullCountryByCode(
                                        order?.drpaymentInfo?.billingAddress?.country?.isocode,
                                    ),
                                )}
                            </TextBox>
                        </Flex>
                        <Flex marginBottom={['large', 'large', '', '']} flexDirection='column'>
                            <Flex flexDirection='column'>
                                <HeadingBox color='header.secondaryLinkActive'>
                                    {t('cart.payment.paymentMethod')}
                                </HeadingBox>
                                {false ? (
                                    <Flex>
                                        <PaypalIcon />
                                    </Flex>
                                ) : (
                                    <>
                                        <Flex>
                                            <TextBox color='header.secondaryLinkActive'>
                                                {order?.drpaymentInfo?.drccbrand?.toUpperCase()}
                                            </TextBox>
                                            <CreditCardTypeImage
                                                src={getSmallerCCimg(
                                                    order?.drpaymentInfo?.drccbrand?.toUpperCase(),
                                                )}
                                                alt='payment-card'
                                                marginLeft='0.625rem'
                                                width='1.875rem'
                                            />
                                        </Flex>
                                        <TextBox color='header.secondaryLinkActive'>
                                            **** **** **** {lastFourNums}
                                        </TextBox>
                                    </>
                                )}
                            </Flex>
                        </Flex>
                        {taxData && taxData?.companyName && (
                            <Flex marginBottom={['large', 'large', '', '']} flexDirection='column'>
                                <HeadingBox color='text'>
                                    {t('cart.order.taxExemptionInformation')}
                                </HeadingBox>
                                <TextBox color='text'>{taxData?.companyName}</TextBox>
                                <TextBox color='text'>{taxData?.taxAuthority}</TextBox>
                                <TextBox color='text'>
                                    {t('cart.billing.exemptionStartDateLable')}{' '}
                                    {taxData?.startDate !== ''
                                        ? moment(taxData?.startDate).format('MMM YYYY')
                                        : ' '}
                                </TextBox>
                                <TextBox color='text'>
                                    {t('cart.billing.exemptionEndDateLable')}{' '}
                                    {taxData?.endDate !== ''
                                        ? moment(taxData?.endDate).format('MMM YYYY')
                                        : ' '}
                                </TextBox>
                                <TextBox color='text'>
                                    {t('cart.billing.certFileName')}{' '}
                                    {localStorage.getItem('temp-file-name')
                                        ? localStorage.getItem('temp-file-name')
                                        : 'N/A'}
                                </TextBox>
                            </Flex>
                        )}
                    </FlexBox>
                    <Flex display='flex' flexDirection='column' style={{ marginTop: 40 }}>
                        {order?.unconsignedEntries?.map((item: any, i: any) => (
                            <Flex key={i} display={'flex'} flexDirection={'column'}>
                                <CartItem fromThankYou key={i} item={item} />
                                <SeparatorLine />
                            </Flex>
                        ))}
                    </Flex>
                    <Box>
                        {hasProduct && (
                            <>
                                {/* <Box
                                    display='flex'
                                    width='100%'
                                    justifyContent='flex-end'
                                    paddingBottom='small'>
                                    <Text width='calc(100% - 7.625rem)' textAlign='right'>
                                        {t('subtotalToday')}
                                    </Text>
                                    <Text width='7.625rem' textAlign='right'>
                                        {order?.subTotalWithoutDiscount?.formattedValue}
                                    </Text>
                                </Box> */}

                                {order && order?.appliedVouchers && order?.appliedVouchers?.length > 0 && (
                                    <Box
                                        display='flex'
                                        width='100%'
                                        justifyContent='flex-end'
                                        paddingBottom='small'>
                                        <Text width='calc(100% - 7.625rem)' textAlign='right'>
                                            {/* {t('cart.youSaved')} */}
                                            {t('discount')}
                                        </Text>
                                        <Text width='7.625rem' textAlign='right'>
                                            {'-'}{order?.totalDiscounts?.formattedValue}
                                        </Text>
                                    </Box>
                                )}

                                <Box
                                    display='flex'
                                    width='100%'
                                    justifyContent='flex-end'
                                    paddingBottom='small'>
                                    <Text width='calc(100% - 7.625rem)' textAlign='right'>
                                        {t('cart.shipping.shipping')}
                                    </Text>
                                    <Text width='7.625rem' textAlign='right'>
                                        {convertMoneyAmount(
                                            {
                                                amount: order?.deliveryCost?.value,
                                                currencyCode: order?.deliveryCost?.currencyIso,
                                            },
                                            'en-us',
                                        )}
                                    </Text>
                                </Box>

                            </>
                        )}
                        <Box
                            display='flex'
                            width='100%'
                            justifyContent='flex-end'
                            paddingBottom='small'>
                            <Text width='calc(100% - 7.625rem)' textAlign='right'>
                                {t('tax')} {t('notYetCalculated')}
                            </Text>

                            <Text width='7.625rem' textAlign='right'>
                                {convertMoneyAmount(
                                    {
                                        amount: order?.totalTax?.value,
                                        currencyCode: order?.totalTax?.currencyIso,
                                    },
                                    'en-us',
                                )}
                            </Text>
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            display='flex'
                            alignItems='center'
                            width='100%'
                            justifyContent='flex-end'>
                            <Heading
                                letterSpacing='normal'
                                fontSize='medium'
                                width='calc(100% - 7.625rem)'
                                textAlign='right'>
                                {t('totalToday')}
                            </Heading>
                            <Heading
                                letterSpacing='normal'
                                width='7.625rem'
                                fontSize='medium'
                                textAlign='right'>
                                {convertMoneyAmount(
                                    {
                                        amount: order?.totalPriceWithTax?.value,
                                        currencyCode: order?.totalPriceWithTax?.currencyIso,
                                    },
                                    'en-us',
                                )}
                            </Heading>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default ThankYou;
