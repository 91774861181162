import React, { FC, useRef, useState, useEffect} from 'react';
import {
  Input,
  Box,
  Text,
  Image,
  Flex,
  Button,
} from '@resideo/blueprint-react';
import Collapsible from 'react-collapsible';
import { CloseRoundGreyImg, downArrowIcon, upArrowIcon } from 'assets/images';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { STATES } from 'config/states';
import { InputField, SelectField } from 'components/common/InputField';
import { Container } from './BillingAddress';
import SeparatorLine from '../../common/SeparatorLine';
import Alert from 'components/common/Alert';
import { useToast } from 'components/common/Toast';
import moment from 'moment';
import { SuccessAlert, ErrorAlert } from 'components/common/Alert';

const CloseBtn = styled(Box)`
  cursor: pointer;
`;

const StyledCursorImage = styled(Image)`
  cursor: pointer;
`;

const TaxExemption: FC<any> = ({
  values,
  setFieldValue,
  errors,
  taxExempt,
  setTaxExempt,
  setFile,
  uploadedImg,
  setUploadedImg,
  setTaxInfoChanged,
  setTaxInfoComplete,
  dateValidated,
}) => {
  let myInput: any = useRef();
  const { t } = useTranslation();
  const toast = useToast();

  // We need to check if there is session data and set state accordingly for logic to work
  const [startDate, setStartDate] = useState(
    sessionStorage.getItem('temp-tax-start-date')
      ? sessionStorage.getItem('temp-tax-start-date')
      : ''
  );
  const [endDate, setEndDate] = useState(
    sessionStorage.getItem('temp-tax-start-date')
      ? sessionStorage.getItem('temp-tax-start-date')
      : ''
  );
  const [companyName, setCompanyName] = useState(
    sessionStorage.getItem('temp-company-name')
      ? sessionStorage.getItem('temp-company-name')
      : ''
  );
  const [taxAuthority, setTaxAuthority] = useState(
    sessionStorage.getItem('temp-tax-state')
      ? sessionStorage.getItem('temp-tax-state')
      : ''
  );
  const [certificateUploaded, setCertificateUploaded] = useState('');
  const [taxInfoEntryInitiated, setTaxInfoEntryInitiated] = useState('false');

  const uploadImage = async e => {
    setCertificateUploaded('true');
    e.preventDefault();
    const file = e.target.files[0];
    if(isValidFileUploaded(file)){
    setFile(file);
    setUploadedImg(file.name);
    setFieldValue('taxCertificate', file.name);
    localStorage.setItem('temp-file-name', file.name);
  }else{
    toast(
      <Alert variant='error'>
        <Text>{'Invalid File Format'}</Text>
      </Alert>,
      { duration: 2000 }
    );
  }
  };

  const isValidFileUploaded=(file)=>{
    const validExtensions = ['png','jpg','pdf']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
  }

  useEffect(() => {
    // Comprehensively checking to ensure all four values are present or not:
    if (
      (sessionStorage.getItem('temp-company-name') === null ||
        sessionStorage.getItem('temp-company-name') === '') &&
      (sessionStorage.getItem('temp-tax-state') === null ||
        sessionStorage.getItem('temp-tax-state') === '') &&
      (sessionStorage.getItem('temp-tax-start-date') === null ||
        sessionStorage.getItem('temp-tax-start-date') === '') &&
      (sessionStorage.getItem('temp-tax-end-date') === null ||
        sessionStorage.getItem('temp-tax-start-date') === '')
    ) {
      setTaxExempt(false);
    } else {
      setTaxExempt(true);
    }
  }, []);

  useEffect(() => {
    //* Form: Inactive State:
    if (
      companyName === '' &&
      taxAuthority === '' &&
      certificateUploaded === '' &&
      startDate === '' &&
      endDate === ''
    ) {
      setTaxInfoEntryInitiated('false');
      setTaxInfoComplete(true);
      // Notice we send data as if its complete to not disable review order button
    } else {
      setTaxInfoEntryInitiated('true');
    }
    //* Form: Active State:
    if (taxInfoEntryInitiated !== 'false') {
      if (
        startDate &&
        endDate &&
        companyName &&
        taxAuthority &&
        certificateUploaded
      ) {
        setTaxInfoComplete(true);
      } else {
        setTaxInfoComplete(false);
      }
    }
  }, [
    taxInfoEntryInitiated,
    certificateUploaded,
    companyName,
    taxAuthority,
    startDate,
    endDate,
  ]);

  const checkDates = endDate => {
    if (startDate != undefined) {
      if (startDate < endDate) {
        return true;
      }
    }
  };

  const taxAuthorityUtilFn = state => {
    if (state !== 'Select a State') {
      sessionStorage.setItem('temp-tax-state', state);
      setTaxAuthority(state);
      setFieldValue('taxExemptionApplication.taxAuthority', state);
    } else {
      sessionStorage.setItem('temp-tax-state', '');
      setTaxAuthority('');
      setFieldValue('taxExemptionApplication.taxAuthority', '');
    }
  };

  return (
    <Box data-test-payment-sections>
      <Text
        data-test-section-title
        paddingY='medium'
        fontWeight='bold'
        fontSize='1.313rem'>
        {t('cart.billing.taxExemptionApplication')}
      </Text>
      <Container isError={errors.taxExemptionApplication}>
        <Collapsible
          open={taxExempt}
          onOpening={() => setTaxExempt(true)}
          onClosing={() => setTaxExempt(false)}
          trigger={
            <CloseBtn
              display={taxExempt ? 'none' : 'flex'}
              justifyContent='space-between'>
              <Text color='primary' fontWeight='500'>
                {taxExempt ? t('close') : t('cart.payment.expandTaxExemption')}
              </Text>
              <Image
                src={taxExempt ? upArrowIcon : downArrowIcon}
                alt='down-arrow'
              />
            </CloseBtn>
          }>
          <Text fontSize='small' paddingBottom='medium' fontStyle={'italic'}>
            {t('common.requiredFields')}
          </Text>
          <Box data-test-tax-inputs marginBottom='medium'>
            <InputField
              autoComplete='off'
              label={t('cart.billing.companyName')}
              name='taxExemptionApplication.companyName'
              // value={values.taxExemptionApplication.companyName || ''}
              // onChange={e =>
              //   setFieldValue(
              //     'taxExemptionApplication.companyName',
              //     e.target.value
              //   )
              // }
              value={
                sessionStorage.getItem('temp-company-name')
                  ? sessionStorage.getItem('temp-company-name')
                  : ''
              }
              onChange={e => {
                setTaxInfoEntryInitiated('true');
                setCompanyName(e.target.value);
                setFieldValue(
                  'taxExemptionApplication.companyName',
                  e.target.value
                );
                sessionStorage.setItem('temp-company-name', e.target.value);
                setTaxInfoChanged(true);
                !e.target.value
                  ? setTaxInfoChanged(false)
                  : setTaxInfoChanged(true);
              }}
            />
          </Box>
          <Box data-test-tax-inputs marginBottom='medium'>
            <SelectField
              name='taxExemptionApplication.taxAuthority'
              label={t('cart.billing.exemptionCertificate')}
              // value={values.taxExemptionApplication.taxAuthority || ''}>
              onChange={e => taxAuthorityUtilFn(e.target.value)}
              value={
                sessionStorage.getItem('temp-tax-state')
                  ? sessionStorage.getItem('temp-tax-state')
                  : values.taxExemptionApplication.taxAuthority
                  ? values.taxExemptionApplication.taxAuthority
                  : ''
              }>
              {STATES.map((obj, i) => (
                <option
                  disabled={obj.value === 'Select a State'}
                  // onChange={() =>
                  //   setFieldValue(
                  //     'taxExemptionApplication.taxAuthority',
                  //     obj.value
                  //   )
                  // }
                  key={i}>
                  {obj.text}
                </option>
              ))}
            </SelectField>
          </Box>
          <Box data-test-tax-inputs marginBottom='medium'>
            <Flex display={['block', 'block', 'block', 'flex']} justifyContent={'space-between'} width={'98%'}>
              <Box marginBottom='medium'
                  flex={1}>
                <InputField
                  autoComplete='off'
                  name='startDate'
                  label={t('cart.billing.exemptionStartDate')}
                  //defaultValue={values.taxExemptionApplication.startDate}
                  type='date'
                  value={
                    sessionStorage.getItem('temp-tax-start-date') ?? startDate
                  }
                  isDatePicker={true}
                  //required={taxExempt}
                  // onChange={e => {
                  //   setFieldValue(
                  //     'taxExemptionApplication.startDate',
                  //     e.target.value
                  //   );
                  // }}
                  onChange={e => {
                    if (moment().isBefore(e.target.value)) {
                      toast(
                        <Alert variant='error'>
                          <Text>
                            &nbsp;The tax certificate start date must be before
                            today&rsquo;s date.
                          </Text>
                        </Alert>,
                        { duration: 10000 }
                      );
                    } else {
                      setFieldValue(
                        'taxExemptionApplication.startDate',
                        e.target.value
                      );
                      setStartDate(e.target.value);
                      sessionStorage.setItem(
                        'temp-tax-start-date',
                        e.target.value
                      );
                    }
                  }}
                />
              </Box>
              <Box marginBottom='medium'
                flex={1}>
                <InputField
                  name='endDate'
                  label={t('cart.billing.exemptionEndDate')}
                  //defaultValue={values.taxExemptionApplication.endDate}
                  type='date'
                  isDatePicker={true}
                  value={sessionStorage.getItem('temp-tax-end-date') ?? endDate}
                  required={taxExempt}
                  // onChange={e => {
                  //   setFieldValue(
                  //     'taxExemptionApplication.endDate',
                  //     e.target.value
                  //   );
                  // }}
                  onChange={e => {
                    setFieldValue(
                      'taxExemptionApplication.endDate',
                      e.target.value
                    );
                    if (e.target.value && !checkDates(e.target.value)) {
                      dateValidated(false);
                      toast(
                        <Alert variant='error'>
                          <Text>
                            &nbsp;The tax certificate end date must be greater
                            than the start date.
                          </Text>
                        </Alert>,
                        { duration: 10000 }
                      );
                    } else {
                      dateValidated(true);
                      setEndDate(e.target.value);
                      sessionStorage.setItem(
                        'temp-tax-end-date',
                        e.target.value
                      );
                    }
                  }}
                  marginLeft={['none', 'none', 'none', 'small']}
                />
              </Box>
            </Flex>
          </Box>
          <Flex alignItems='center' paddingY='medium'>
            <Text fontSize={['small', 'medium']} fontWeight='500'>
              {t('cart.billing.uploadCertificate')}
            </Text>
            <Box marginLeft='small'>
              <Input
                id='myInput'
                name='file'
                style={{ display: 'none' }}
                type={'file'}
                ref={ref => (myInput = ref)}
                onChange={e => uploadImage(e)}
              />
              <Button
                data-test-shipping-add-new-address
                onClick={() => myInput.click()}
                variant='secondary'
                marginX='medium'
                paddingX='2.5rem'
                type='button'>
                {t('uploadFile')}
              </Button>
            </Box>
          </Flex>
          {uploadedImg && (
            <Box display='flex' justifyContent='flex-start' alignItems='center'>
              <Text color='gray' marginBottom='small'>
                {uploadedImg}
              </Text>
              <StyledCursorImage
                marginLeft='small'
                width='1rem'
                src={CloseRoundGreyImg}
                alt='close'
                onClick={() => {
                  setFile(null);
                  setUploadedImg('');
                }}
              />
            </Box>
          )}
          <Text fontFamily={'Roboto'} fontSize={'14px'}>
            {t('cart.payment.supportedFile')}
          </Text>
          {taxExempt && <SeparatorLine />}
          <CloseBtn
            //onClick={() => setTaxExempt(false)}
            onClick={() => {
              setTaxExempt(false);
              if (
                values.taxExemptionApplication.companyName &&
                (values.taxExemptionApplication.taxAuthority === '' ||
                  values.taxExemptionApplication.startDate === '' ||
                  values.taxExemptionApplication.endDate === '')
              ) {
                console.log('missing');
              }
            }}
            display={!taxExempt ? 'none' : 'flex'}
            justifyContent='space-between'>
            <Text color='primary' fontWeight='500'>
              {taxExempt ? t('close') : t('cart.payment.expandTaxExemption')}
            </Text>
            <Image
              src={taxExempt ? upArrowIcon : downArrowIcon}
              alt='down-arrow'
            />
          </CloseBtn>
        </Collapsible>
      </Container>
    </Box>
  );
};

export default TaxExemption;
