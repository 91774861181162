import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { ReactComponent as ActiveNotificationBell } from 'components/icons/active-notifications-bell.svg';
import { ReactComponent as NotificationsBell } from 'components/icons/notifications-bell.svg';
import { ReactComponent as CartIcon } from 'components/icons/cart-icon.svg';
import { Button, Flex, Text } from '@resideo/blueprint-react';
import { SuccessAlert, ErrorAlert } from 'components/common/Alert';
import styled, { keyframes } from 'styled-components';
import { useToast } from 'components/common/Toast';
import { useIsMounted } from 'hooks/useIsMounted';
import { fetchQuery } from 'relay-runtime';
import { RootState } from 'redux/root-reducer';
import { useSelector } from 'react-redux';
import CartPreview from 'components/cart/CartPreview';

const StyledMenuButton = styled(MenuButton)`
    cursor: pointer;
    transform: scale(1.23);
`;

const NotificationRowComponent = ({ node, fetch }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const isMounted = useIsMounted();

    return (
        <MenuItem
            onSelect={() => {
                // acceptInvite();
            }}>
            <Flex
                justifyContent='space-between'
                flexDirection={['column', 'row']}
                data-test-invite-notification>
                <Flex
                    flexDirection='column'
                    marginRight='large'
                    alignItems='self-start'
                    paddingBottom={['medium', 0]}>
                    <Text color='white'>
                        {t('header.notifications.invitedTo')}
                        {` ${node.partnerAccount.name}`}
                    </Text>
                    <Text color='faded' fontSize='small'>
                        {t('header.notifications.invitedBy')}
                    </Text>
                </Flex>
                <Button>{t('header.notifications.acceptInvite')}</Button>
            </Flex>
        </MenuItem>
    );
};

const animateCartDropdownDesktop = keyframes`
  0% {
    top: 65px;
  }

  100% {
    top: 50px;
  }
`;

const CustomCartMenuList = styled(MenuList)`
    position: absolute;
    top: 50px;
    right: -10px;
    overflow: hidden;
    width: calc(100vw - 120px);
    height: 0;
    transition: none;
    background: #fff;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.5);
    padding: 16px;
    z-index: 1;
    &:before {
        position: absolute;
        top: -28px;
        right: 10px;
        display: block;
        width: 0;
        content: '';
        border: 16px solid transparent;
        border-bottom: 14px solid #fff;
    }
    &.rdeCartOpened {
        top: 65px;
        display: block;
        overflow: visible;
        height: auto;
        animation: ${animateCartDropdownDesktop} 0.3s ease;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
    }

    @media (min-width: 767px) {
        width: 650px;
    }

    @media (max-width: 991px) {
        &:before {
            right: 18px;
        }
    }

    @media (min-width: 992px) {
        max-height: 80vh;
        padding: 32px;
    }

    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
`;

export const CartQuantity = styled.div`
    display: inline-block;
    font-size: 9px;
    line-height: 16px;
    position: absolute;
    top: -3px;
    right: -5px;
    width: 16px;
    height: 16px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    background-color: #247f39;
`;

const useMountEffect = (cb: () => void) => useEffect(cb, []);

const Cart: FC = () => {
    const isMounted = useIsMounted();
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState();
    const [cartQuantity, setCartQuantity] = useState();
    const cartItemData: any = useSelector((state: RootState) => state.cartDetails.cartDetails);
    const cartItems =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];

    useEffect(() => {
        setCartQuantity(cartItems?.entries?.length);
    }, []);

    useEffect(() => {
        setCartQuantity(cartItems?.entries?.length);
    }, [cartItemData]);

    const fetchNotifications = () => {};

    useMountEffect(fetchNotifications);

    const notExpired = (edge) => {
        const now = Date.now();
        const currentExpiration = new Date(edge.node?.expirationTime || 0);
        return currentExpiration.getTime() > now;
    };

    return (
        <Flex marginRight='medium' data-test-notifications-menu>
            <Menu>
                <StyledMenuButton onClick={fetchNotifications}>
                    {cartItems && cartItems?.entries?.length > 0 && (
                        <CartQuantity>{cartQuantity}</CartQuantity>
                    )}
                    <CartIcon />
                </StyledMenuButton>
                <CustomCartMenuList className='rdeCartOpened' portal={false}>
                    <CartPreview />
                </CustomCartMenuList>
            </Menu>
        </Flex>
    );
};

export default Cart;
