import React, { FC, useEffect, useState } from 'react';
import ReviewSectionCard from '../../../components/cart/ReviewSectionCard';
import { Button, Box, Text, Flex, Checkbox, Label } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import OrderSummaryDesktop from '../../../components/cart/OrderSummaryDesktop';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import CheckoutSteps from '../../../components/cart/CheckoutSteps';
import { PageTitle } from './Shipping';
import { Form, Formik } from 'formik';
import GoBackButton from '../../../components/common/GoBackButton';
//import { useToast } from 'components/common/Toast';
//import Alert from 'components/common/Alert';
import OrderSummaryMobile from '../../../components/cart/OrderSummaryMobile';
import { backArrow, redAlertIcon } from '../../../assets/images';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { placeOrder, setLoading, reviewOrder } from '../../../redux/checkout/action';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import PageLoading from '../../../components/common/PageLoading';
import PageError from '../../../components/common/PageError';

interface LinkProps {
    externalLink?: boolean;
}

const Link = styled.a<LinkProps>`
    color: ${({ theme }) => theme.colors.text};
`;

interface ReviewCardDataProps {
    type?: string;
    heading?: string;
    email?: string;
    phone?: string;
    shippingContactName?: string;
    shippingCompanyName?: string;
    shippingAddressLine1?: string;
    shippingAddressLine2?: string;
    shippingCity?: string;
    shippingStateProvinceRegionCode?: string;
    shippingZipPostalCode?: string;
    shippingCountryCode?: any;
    shippingMethod?: any;
    billingContactName?: string;
    billingCompanyName?: string;
    billingAddressLine1?: string;
    billingAddressLine2?: string;
    billingCity?: string;
    billingStateProvinceRegionCode?: string;
    billingZipPostalCode?: string;
    billingCountryCode?: string;
    ccType?: string;
    image?: string;
    lastFourNums?: string;
    paymentMode?: string;
    exp?: string;
    hideMe?: any;
    companyName?: string;
    stateName?: string;
    taxAuthority?: string;
    startDate?: string;
    endDate?: string;
    certFileName?: string;
}
const StyledText = styled(Text)`
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    padding: 0 ${({ theme }) => theme.space.xSmall};
`;
const Content = styled(Flex)`
    justify-content: space-between;
    width: 100%;
    flex-direction: column-reverse;
    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        flex-direction: row;
    }
    .left {
        order: 2;
        width: 100%;
        @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
            order: 1;
            margin-right: ${({ theme }) => theme.space.medium};
        }
    }
    .right {
        order: 1;
        width: 100%;
        @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
            order: 2;
            margin-left: ${({ theme }) => theme.space.medium};
        }
    }
`;

const ErrorMessage = styled(Flex)`
    min-height: 70px;
    background-color: #c6361c;
    color: white;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 0 2rem;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 2rem;
        margin-bottom: 20px;
    }
`;

const ButtonBackToPayment = styled.button`
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: bold;
    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

const BoxErrorMessage = styled(Box)``;

const IconError = styled.img`
    filter: brightness(0) invert(1);
    margin-right: 5px;
    height: 14px;
    width: 14px;
`;

const IconBack = styled.img`
    position: absolute;
    margin-left: -40px;
    filter: brightness(0) invert(1);
`;

const ModifiedCheckbox = styled(Checkbox)`
    @media (min-width: ${({ theme }) => theme.space.xSmall}) {
        margin-right: 2.75rem;
    }
`;
interface ReviewOrderState {
    shippingMethod: any;
    shippingAddress: any;
    creditCard: any;
    billingAddress: any;
    email: string;
    phoneNumber: string;
    paymentMethod: string;
}

const ReviewOrder: FC<any> = () => {
    //const toast = useToast();
    const { t } = useTranslation();
    const history = useHistory();
    const hookDispatch = useDispatchPromise();
    const dispatch = useDispatch();
    const reviewOrderList: any = useSelector(
        (state: RootState) => state.checkOutDetails.reviewOrderDetails,
    );
    const isLoading = useSelector((state: RootState) => state.checkOutDetails.reviewOrderSuccess);
    const isError = useSelector((state: RootState) => state.checkOutDetails.reviewOrderError);
    let cartItems =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];

    const hasProduct =
        cartItems &&
        cartItems?.entries?.length > 0 &&
        cartItems?.entries?.filter((x) => x.product.itemType?.toLowerCase() === 'product').length >
        0;

    const [reviewOrderData, setReviewOrderData] = useState<ReviewOrderState>({
        shippingMethod: null,
        shippingAddress: {},
        creditCard: null,
        billingAddress: null,
        email: '',
        phoneNumber: '',
        paymentMethod: '',
    });

    const removeItem = () => {
        let keysToRemove = [
            'cartId',
            'cartItems',
            'billingAddress',
            'shippingAddress',
            'shippingMethod',
            'creditCard',
        ];
        keysToRemove.forEach((key) => {
            localStorage.removeItem(key);
        });
    };

    const fetchReviewOrder = async () => {
        await dispatch(reviewOrder());
    };

    useEffect(() => {
        fetchReviewOrder();
        window.scroll({ top: 0, behavior: 'smooth' })
    }, []);

    useEffect(() => {
        setReviewOrderData({
            shippingMethod: reviewOrderList?.shippingMethod,
            shippingAddress: reviewOrderList?.shippingAddress,
            creditCard: reviewOrderList?.card,
            billingAddress: reviewOrderList?.billingAddress,
            email: reviewOrderList?.email,
            phoneNumber: reviewOrderList?.phone,
            paymentMethod: reviewOrderList?.paymentMethodType,
        });
    }, [reviewOrderList]);

    useEffect(() => {
        console.log('reviewOrderData');
    }, [reviewOrderData]);

    const createOrder = async (values) => {
        dispatch(setLoading(true));
        hookDispatch(placeOrder({ termsValue: values.terms }))
            .then((response: any) => {
                const OrderId = response.code;
                history.push(`/cart/order/${OrderId}`);
                dispatch(setLoading(false));
                removeItem();
            })
            .catch((error) => {
                console.log('Error', error);
                dispatch(setLoading(false));
            });
    };

    const taxData = JSON.parse(`${localStorage.getItem('taxExemptionApplication')}`);
    const reviewCardData = (values): ReviewCardDataProps[] => [
        {
            type: 'info',
            heading: 'cart.orderComplete.contactInformation',
            email: values?.email,
            phone: values?.phone,
        },
        {
            type: 'shippingAddress',
            heading: 'cart.shipping.shippingAddress',
            shippingContactName: values.shippingAddress?.contactName,
            shippingCompanyName: values.shippingAddress?.companyName,
            shippingAddressLine1: values.shippingAddress?.addressLine1,
            shippingAddressLine2: values.shippingAddress?.addressLine2,
            shippingCity: values.shippingAddress?.city,
            shippingStateProvinceRegionCode: values.shippingAddress?.stateProvinceRegionCode,
            shippingZipPostalCode: values.shippingAddress?.zipPostalCode,
            shippingCountryCode: values.shippingAddress?.countryCode,
            hideMe: !hasProduct,
        },
        {
            type: 'shippingMethod',
            heading: 'cart.shipping.shippingMethod',
            shippingMethod: values.shippingMethod,
            hideMe: !hasProduct,
        },
        {
            type: 'billingAddress',
            heading: 'cart.billing.billingAddress',
            billingContactName: values.billingAddress?.contactName,
            billingCompanyName: values.billingAddress?.companyName,
            billingAddressLine1: values.billingAddress?.addressLine1,
            billingAddressLine2: values.billingAddress?.addressLine2,
            billingCity: values.billingAddress?.city,
            billingStateProvinceRegionCode: values.billingAddress?.stateProvinceRegionCode,
            billingZipPostalCode: values.billingAddress?.zipPostalCode,
            billingCountryCode: values.billingAddress?.countryCode,
        },
        {
            type: 'paymentMethod',
            heading: 'cart.payment.paymentMethod',
            paymentMode: values?.paymentMethodType,
            ccType: values?.card?.type,
            image: values?.card?.image,
            lastFourNums: values.card?.lastFourNums,
            exp: values?.card?.exp,
        },
        {
            type: 'taxExemption',
            heading: 'cart.completeOrder.taxExemptionInformation',
            companyName: taxData?.companyName,
            stateName: '',
            taxAuthority: taxData?.taxAuthority,
            startDate: taxData?.startDate,
            endDate: taxData?.endDate,
            certFileName: 'Test.jpg',
            hideMe: taxData?.companyName ? false : true
        },
    ];

    const data = () => ({
        email: reviewOrderData?.email,
        phone: reviewOrderData?.phoneNumber,
        shippingAddress: {
            contactName: reviewOrderData?.shippingAddress?.contactName,
            addressLine1: reviewOrderData?.shippingAddress?.addressLine1,
            addressLine2: reviewOrderData?.shippingAddress?.addressLine2,
            city: reviewOrderData?.shippingAddress?.city,
            stateProvinceRegionCode: reviewOrderData?.shippingAddress?.stateProvinceRegionCode,
            countryCode: reviewOrderData?.shippingAddress?.countryCode,
            zipPostalCode: reviewOrderData?.shippingAddress?.zipPostalCode,
        },
        shippingMethod: reviewOrderData?.shippingMethod,
        billingAddress: {
            contactName: reviewOrderData?.billingAddress?.contactName,
            addressLine1: reviewOrderData?.billingAddress?.addressLine1,
            addressLine2: reviewOrderData?.billingAddress?.addressLine2,
            city: reviewOrderData?.billingAddress?.city,
            stateProvinceRegionCode: reviewOrderData?.billingAddress?.stateProvinceRegionCode,
            countryCode: reviewOrderData?.billingAddress?.countryCode,
            zipPostalCode: reviewOrderData?.billingAddress?.zipPostalCode,
        },
        card: {
            type: reviewOrderData?.creditCard?.type,
            image: reviewOrderData?.creditCard?.image,
            lastFourNums: reviewOrderData?.creditCard?.lastFourNums,
            cvc: '',
            exp: reviewOrderData?.creditCard?.exp,
            firstNum: '',
            name: '',
            number: '',
        },
        paymentMethodType: 'CREDIT_CARD',
        terms: false,
    });

    return isLoading && !reviewOrderList.length && reviewOrderData.email !== '' ? (
        <PageLoading />
    ) : isError ? (
        <PageError />
    ) : (
        <Box data-test-review-order-page>
            <CheckoutSteps step4 />
            <Box marginBottom='medium' marginX='large'>
                <PageTitle data-test-page-title paddingTop={['medium', 'medium', 'large', 'large']}>
                    {t('cart.reviewOrder.reviewOrderDetails')}
                </PageTitle>
                {false && (
                    <ErrorMessage>
                        <BoxErrorMessage>
                            <IconError src={redAlertIcon} alt='error' />
                            {'Error Message'}
                        </BoxErrorMessage>
                        <ButtonBackToPayment onClick={() => history.push('/cart/payment')}>
                            <IconBack src={backArrow} alt='back' />{' '}
                            {t('cart.payment.backToPayment')}
                        </ButtonBackToPayment>
                    </ErrorMessage>
                )}
                <Formik enableReinitialize initialValues={data()} onSubmit={createOrder}>
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <Flex marginBottom='medium'>
                                    <OrderSummaryMobile setFieldValue={setFieldValue} values={values} />
                                </Flex>
                                <Content>
                                    <Box className='left'>
                                        {reviewCardData(values)
                                            .filter((f) => !f.hideMe)
                                            .map((card, i) => (
                                                <ReviewSectionCard
                                                    key={i}
                                                    card={card}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                />
                                            ))}
                                    </Box>
                                    <Box className='right' color='textLight3'>
                                        <OrderSummaryDesktop
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                        <Flex
                                            data-test-agree-terms
                                            textAlign='left'
                                            display='inline-block'
                                            fontSize='small'
                                            paddingY='medium'>
                                            {t('cart.payment.agreeAndSubmitText.1')}
                                            <StyledText>
                                                <Link
                                                    target='blank'
                                                    href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRTermsAndConditionsPage/eCommerceProvider.DR+globalTech+Inc'>
                                                    {t('cart.payment.agreeAndSubmitText.2')}
                                                </Link>
                                            </StyledText>
                                            <StyledText>
                                                <Link
                                                    target='blank'
                                                    href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRPrivacyPolicyPage/eCommerceProvider.DR+globalTech+In'>
                                                    {t('cart.payment.agreeAndSubmitText.4')}
                                                </Link>
                                            </StyledText>
                                            {t('cart.payment.agreeAndSubmitText.6')}
                                        </Flex>
                                        <Flex
                                            data-test-agree-terms
                                            textAlign='left'
                                            display='inline-block'
                                            fontSize='small'>
                                            {t('cart.payment.agreeAndSubmitText.7')}
                                        </Flex>
                                        <Flex
                                            data-test-agree-terms
                                            textAlign='left'
                                            display='inline-block'
                                            fontSize='small'
                                            paddingTop='medium'>
                                            {t('cart.payment.agreeAndSubmitText.8')}
                                        </Flex>
                                        <Flex
                                            alignItems='center'
                                            justifyContent='flex-start'
                                            display='flex'>
                                            <Label
                                                paddingBottom='medium'
                                                style={{ margin: '1.063rem 0 1.625rem 0' }}>
                                                <ModifiedCheckbox
                                                    disabled={false}
                                                    name='terms'
                                                    type='checkbox'
                                                    marginRight='0'
                                                    onChange={(e) =>
                                                        setFieldValue('terms', e.target.checked)
                                                    }
                                                    checked={values.terms}
                                                />
                                                <Text
                                                    fontSize='small'
                                                    fontWeight='normal'
                                                    color={'textHeading'}
                                                    fontStyle='italic'>
                                                    {t('cart.payment.agreeAndSubmitText.9')}
                                                </Text>
                                            </Label>
                                        </Flex>
                                        <Flex
                                            justifyContent='flex-end'
                                            alignItems='center'
                                            flexDirection={[
                                                'column-reverse',
                                                'column-reverse',
                                                'column-reverse',
                                                'row',
                                            ]}>
                                            <GoBackButton
                                                url={`${process.env.PUBLIC_URL ?? ''}/cart/payment`}
                                                text='Back to Payment'
                                            />
                                            <Button
                                                disabled={!values.terms}
                                                marginLeft={['0', '0', '0', 'small']}
                                                data-test-go-to-order-completed
                                                type='submit'
                                                width={['100%', 'auto', 'auto', 'auto']}
                                                variant='primary'
                                                marginBottom={['large', 'large', 'small', '0']}>
                                                {t('cart.reviewOrder.agreeAndCompleteOrder')}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Content>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
export default ReviewOrder;
