import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    creatingB2bUser: false,
    b2bUser: {},
    creatingB2bUserErrMsg: null,
    isB2bUserPresent: false,
};

const b2bUserDetails = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch (type) {
        case types.CREATE_B2B_PRO_USER:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingB2bUser = true;
            });
        case types.CREATE_B2B_PRO_USER_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingB2bUser = false;
                draftState.b2bUser = payload;
                draftState.isB2bUserPresent = true;
            });
        case types.CREATE_B2B_PRO_USER_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creatingB2bUser = false;
                const { type } = payload.data.errors[0];
                if (type === 'CustomerAlreadyExistsError') {
                    draftState.isB2bUserPresent = true;
                }
                draftState.creatingB2bUserErrMsg = payload;
            });
        default:
            return state;
    }
};

export { b2bUserDetails };
