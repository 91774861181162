import { gql } from '@redux-requests/graphql';
import { types } from './types';
import node from '../__generated__/ProfileQuery.graphql';

export const graphQltest = (payload?: string) => ({
    type: types.FETCH_USER_DETAILS,
    request: {
        query: gql`
            {
                companies {
                    name
                    contactPerson
                }
            }
        `,
        variables: { payload },
    },
});

export const partnerProgramServiceTest = () => ({
    type: types.FETCH_USER_DETAILS,
    request: {
        query: gql`
            {
                partnerPrograms {
                    edges {
                        node {
                            partnerProgramProducts {
                                edges {
                                    node {
                                        id
                                        partnerProgram {
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
    },
});
const url = process.env.REACT_APP_GATEWAY_URL || "/";
export const encodeId = ({ appName, appVersion, md5Hash }) => btoa(`${appName}:${appVersion}:${md5Hash}`);
export const getUser = () => ({
    type: types.FETCH_USER_DETAILS,
    payload: {
        request: {
          url: process.env.REACT_APP_USE_PERSISTED_QUERIES === 'true' ?  `${url}/${encodeId({appName: process.env.REACT_APP_NAME, appVersion: process.env.REACT_APP_VERSION, md5Hash: node.params.id})}` : url,
          method: 'post',
        },
    }
});

export const getSuccess = (payload: any) => ({
    type: types.FETCH_USER_DETAILS_SUCCESS,
    payload,
});

export const getFail = (payload: any) => ({
    type: types.FETCH_USER_DETAILS_FAIL,
    payload,
});
