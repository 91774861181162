import { Box, Input, Text, Card } from '@resideo/blueprint-react';
import { ReactComponent as CloseRoundImg } from '../../assets/images/blue_plus_add_promo_code.svg';
import { ReactComponent as RedAlertIcon } from '../../assets/images/alert_icons/ic_warning_red.svg';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { createPromoCode } from 'redux/promocode/action';
import { getCardDetails } from 'redux/cart/actions';

const StyledPromoCodeInput = styled(Input)`
  border: none !important;
  padding: 2px 0 1px 1px;
  &:focus {
    box-shadow: none !important;
  }
`;

const StyledText = styled(Text)`
  border-radius: 25px;
  cursor: pointer;
  width: 85px;
  height: 45px;
  text-align: center;
  margin-left: 20px;
  margin-top: 5px;
  line-height: 45px;
  &.disabled {
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray};
    }
    border-color: ${({ theme }) => theme.colors.gray};
  }
`;
const ErrorMessage = styled(Box)`
  font-weight: bold;
  color: #c6361c;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  display: flex;
  align-items: center;
`;

const StyledBorder = styled(Card)`
  border-radius: 0.9375rem;
  &.blue-border {
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  }
  &.gray-border {
    border: 2px solid ${({ theme }) => theme.colors.collapseBorder};
  }
  &.red-border {
    border: 2px solid ${({ theme }) => theme.colors.redAlertError};
  }

  .un-focus {
    margin-top: 7px;
    margin-bottom: -10px;
    font-size: 1rem !important;
    z-index: -1;
  }
  .un-focus-place-holder {
    margin-top: 6px;
    margin-bottom: -10px;
    z-index: 1;
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
`;

const StyledTextUpper = styled(Text)`
  text-transform: uppercase;
`;

const StyledCloseRoundImg = styled(CloseRoundImg)`
  margin-right: ${({ theme }) => theme.space.xSmall};
`;

const AddPromoCode = ({ setFieldValue }: { setFieldValue?: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hookDispatch = useDispatchPromise();
  const [showInput, setShowInput] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isError, setIsError] = useState(false);
  const inputEl = useRef(null) as any;
  const [isErrorMessage, setIsErrorMessage] = useState('');

  const applyPromoCode = async () => {
    hookDispatch(createPromoCode({ voucherId: promoCode }))
      .then((response) => {
        dispatch(getCardDetails());
        setPromoCode('');
      })
      .catch((error: any) => {
        console.log('Error', error);
        setIsErrorMessage(error[0]?.message)
        setIsError(true);
      });
  };

  const clearError = () => {
    setIsFocus(true);
    setIsError(false);
    setIsErrorMessage('');
  }

  return (
    <Box display='flex' flexDirection='column'>
      {!showInput ? (
        <StyledBox
          display='flex'
          alignItems='center'
          onClick={() => setShowInput(!showInput)}>
          <StyledCloseRoundImg />
          <StyledTextUpper
            fontWeight='bold'
            fontSize='small'
            color='primary'
            className='no-radius'>
            {t('cart.addPromoCode')}
          </StyledTextUpper>
        </StyledBox>
      ) : (
        <>
          <Box display='flex' alignItems='column'>
            <Box>
              <StyledBorder
                maxHeight='3.25rem'
                marginBottom='small'
                minWidth={['9.375rem', '11rem', '14.375rem', '14.375rem']}
                borderRadius='0'
                backgroundColor='white'
                padding={'8px 15px 8px 15px'}
                className={isError ? 'red-border' : 'gray-border'}>
                {isFocus && (
                  <Text
                    className={isFocus || promoCode ? '' : 'un-focus'}
                    color='faded'
                    fontSize='0.75rem'
                    paddingLeft='1px'
                    onClick={() => {
                      setIsFocus(true);
                      inputEl.current.focus();
                    }}>
                    {t('cart.promoCode')}
                  </Text>
                )}
                {!isFocus && (
                  <Text
                    className={'un-focus-place-holder'}
                    color='#767676'
                    fontSize='1rem'
                    paddingLeft='1px'
                    onClick={() => {
                      setIsFocus(true);
                      inputEl.current.focus();
                    }}>
                    {t('cart.promoCode')}
                  </Text>
                )}
                <StyledPromoCodeInput
                  onChange={e => setPromoCode(e.target.value)}
                  value={promoCode}
                  fontWeight='500'
                  name='promo'
                  type='text'
                  fontSize='14px'
                  lineHeight='16px'
                  className='no-border'
                  color={isError ? '#c6361c' : ''}
                  ref={inputEl}
                  style={{ textTransform: 'uppercase' }}
                  onFocus={() => clearError()}
                  onBlur={() => (promoCode ? null : setIsFocus(false))}
                />
              </StyledBorder>
            </Box>
            <Box>
              <StyledText
                onClick={() => {
                  setFieldValue('promoCode', promoCode);
                  applyPromoCode();
                }}
                marginBottom='small'
                color='white'
                backgroundColor={isError || !promoCode ? 'gray' : 'primary'}>
                {t('apply')}
              </StyledText>
            </Box>
          </Box>
          {isError && (
            <ErrorMessage>
              <RedAlertIcon />
              &nbsp;{isErrorMessage}
            </ErrorMessage>
          )}
        </>
      )}
    </Box>
  );
};

export default AddPromoCode;