import { all } from 'redux-saga/effects';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer, requestsMiddleware } from './root-reducer';
import sagaMonitor from './sagaMonitor/sagaMonitor';
import storage from 'redux-persist/lib/storage';

import orderSummarySaga from './orderSummary/saga';
import langaugeSaga from './language/saga';
import categorySaga from './category/saga';
import productListSaga from './productlist/saga';
import productDetailSaga from './productdetails/saga';
import authenticationSaga from './authentication/saga';
import tokenRefreshSaga from './refreshToken/saga';
import cartSaga from './cart/saga';
import checkoutListSaga from './checkout/saga';
import userSaga from './user/saga';
import subscriptionListSaga from './subscriptions/saga';
import digitalRiverSaga from './digitalriver/saga';
import promoCodeSaga from './promocode/saga';
import b2bUserSaga from './prouser/saga';

const effectsMiddleware = (next: any) => (effect: any) => {
    if (effect.type === 'PUT') {
        const { action } = effect.payload;
        const status = action.payload && action.payload.status;
        if (status === 401) {
            const effect401 = {
                ...effect,
                payload: {
                    ...effect.payload,
                    action: {
                        ...action,
                        meta: { errorStatus: 401 },
                    },
                },
            };
            return next(effect401);
        }
        return next(effect);
    }
    return next(effect);
};

const errorMiddleWare = (store: any) => (next: any) => (action: any) => {
    if (action.meta && action.meta.errorStatus && action.meta.errorStatus === 401 || action.meta && action.meta.requestAction &&  action.meta.requestAction.payload && action?.meta?.requestAction?.payload?.status === 400) {
        const { refreshingToken } = store.getState().tokenRefresh;
        // if (action.type === 'REFRESH_TOKEN' && refreshingToken) {
        //     return;
        // }
        // if (action.type !== 'REFRESH_TOKEN' && !refreshingToken) {
        //     store.dispatch({ type: 'REFRESH_TOKEN' });
        // }
        return;
    }
    next(action);
};

const persistConfig: any = {
    key: 'root',
    storage,
    whitelist: ['*'],
    timeout: 0,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const sagaMiddleWare = createSagaMiddleware({
    sagaMonitor,
    effectMiddlewares: [effectsMiddleware],
})

const composeEnhancer = composeWithDevTools({
    trace: true,
    traceLimit: 25
})

const store: any = createStore(persistedReducer, composeEnhancer(applyMiddleware(sagaMiddleWare, errorMiddleWare, ...requestsMiddleware)))

export default function* rootSaga() {
    yield all ([
        langaugeSaga(),
        categorySaga(),
        productListSaga(),
        productDetailSaga(),
        orderSummarySaga(),
        authenticationSaga(),
        tokenRefreshSaga(),
        cartSaga(),
        checkoutListSaga(),
        userSaga(),
        subscriptionListSaga(),
        digitalRiverSaga(),
        promoCodeSaga(),
        b2bUserSaga(),
    ])
}

export const persistor = persistStore(store);

sagaMiddleWare.run(rootSaga);

export { store }