import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { subscriptionUrl } from '../../config/api/url';
import { ordersUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import {
    getSubscriptionListSuccess,
    getSubscriptionListFail,
    getSubscriptionDetailsSuccess,
    getSubscriptionDetailsFail,
    getCompleteSubscriptionBillSuccess,
    getCompleteSubscriptionBillFail,
    getOrdersByOrderNumberSuccess,
    getOrdersByOrderNumberFail,
    cancelSubscriptionServiceSuccess,
    cancelSubscriptionServiceFail,
    renewalSubscriptionServiceSuccess,
    renewalSubscriptionServiceFail,
    getSubscriptionBillingDetailsSuccess,
    getSubscriptionBillingDetailsFail,
    createDRSubscriptionPaymentSuccess,
    createDRSubscriptionPaymentFail,
    availableSubscriptionDetailsSuccess,
    availableSubscriptionDetailsFail
} from './actions';
import { resolve } from 'path';

function* getSubscriptionList(): any {
    try {
        const url = subscriptionUrl + `current/subscriptions`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getSubscriptionListSuccess(response.listOfSubscriptions));
    } catch (error) {
        yield put(getSubscriptionListFail(error));
    }
}

function* getSubscriptionDetails({ payload, resolve, reject }: any): any {
    const { subscriptionId } = payload;
    try {
        const url = subscriptionUrl + `current/subscriptions/${subscriptionId}`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getSubscriptionDetailsSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(getSubscriptionDetailsFail(error));
        reject(error);
    }
}

function* refreshSubscriptionDetails({ payload }: any): any {
    const { subscriptionId } = payload;
    try {
        const url = subscriptionUrl + `current/subscriptions/${subscriptionId}`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getSubscriptionDetailsSuccess(response));
    } catch (error) {
        yield put(getSubscriptionDetailsFail(error));
    }
}

function* deleteSubscriptionService({ payload, resolve, reject }: any): any {
    const { subscriptionId, cancelReason, cancelledDate } = payload;
    try {
        const url =
            subscriptionUrl +
            `current/subscriptions/${subscriptionId}/cancellation?cancellationDate=${cancelledDate}&cancellationReason=${cancelReason}`;
        const response = yield call(api, url, httpRequest.POST, {}, 0, 0);
        yield put(cancelSubscriptionServiceSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(cancelSubscriptionServiceFail(error));
        reject(error);
    }
}

function* renewalSubscriptionService({ payload, resolve, reject }: any): any {
    const { subscriptionId, renewalType } = payload;
    try {
        const url =
            subscriptionUrl +
            `current/subscriptions/${subscriptionId}/renewalType?termOfServiceRenewal=${renewalType}`;
        const response = yield call(api, url, httpRequest.POST, {}, 0, 0);
        yield put(renewalSubscriptionServiceSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(renewalSubscriptionServiceFail(error));
        reject(error);
    }
}

function* getOrdersByOrderNumber({ payload, resolve, reject }: any): any {
    const { invoiceId } = payload;
    try {
        const url = ordersUrl + `current/orders/${invoiceId}?fields=FULL`;
        const response = yield call(api,url,httpRequest.GET,null,2);
        yield put(getOrdersByOrderNumberSuccess(response.orders));
        resolve(response);
    } catch (error) {
        yield put(getOrdersByOrderNumberFail(error));
        reject(error);
    }
}

function* getCompleteSubscriptionBill({ payload, resolve, reject }: any): any {
    const { subscriptionId } = payload;
    try {
        const url = subscriptionUrl + `current/subscriptions/${subscriptionId}/bills`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getCompleteSubscriptionBillSuccess(response.orders));
        resolve(response);
    } catch (error) {
        yield put(getCompleteSubscriptionBillFail(error));
        reject(error);
    }
}

function* getSubscriptionBillingDetails({ payload }: any): any {
    const { subscriptionId } = payload;
    try {
        const url = subscriptionUrl + `current/subscriptions/${subscriptionId}/sbBills`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getSubscriptionBillingDetailsSuccess(response.bills));
    } catch (error) {
        yield put(getSubscriptionBillingDetailsFail(error));
    }
}

function* createDRsubscriptionPayment({ payload, resolve, reject }: any): any {
    const { dRPaymentDetailsForm, subscriptionId } = payload;
    try {
        const url = subscriptionUrl + `current/subscriptions/${subscriptionId}/payment`;
        const response = yield call(api, url, httpRequest.PUT, dRPaymentDetailsForm, 0, 0);
        yield put(createDRSubscriptionPaymentSuccess(response));
        resolve(response);
    } catch (error) {
        reject(error);
        yield put(createDRSubscriptionPaymentFail(error));
    }
}
function* getAvailbleSubscriptionList(): any {
    try {
        const url = subscriptionUrl + `current/unsubscribedProducts`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(availableSubscriptionDetailsSuccess(response));
    } catch (error) {
        yield put(availableSubscriptionDetailsFail(error));
    }
}
function* getSubscriptionWatcher() {
    yield takeLatest(types.FETCH_SUBSCRIPTION_LIST, getSubscriptionList);
}

function* getSubscriptionDetailsWatcher() {
    yield takeLatest(types.GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails);
}

function* refreshSubscriptionDetailsWatcher() {
    yield takeLatest(types.REFRESH_SUBSCRIPTION_DETAILS, refreshSubscriptionDetails);
}

function* deleteSubscriptionServiceWatcher() {
    yield takeLatest(types.CANCEL_SUBSCRIPTION_SERVICE, deleteSubscriptionService);
}

function* renewalSubscriptionServiceWatcher() {
    yield takeLatest(types.RENEWAL_SUBSCRIPTION_SERVICE, renewalSubscriptionService);
}

function* getSubscriptionBillingDetailsWatcher() {
    yield takeLatest(types.GET_SUBSCRIPTION_BILLING_DETAILS, getSubscriptionBillingDetails);
}

function* getOrdersByOrderNumberWatcher() {
    yield takeLatest(types.GET_ORDERS_BY_ORDER_NUMBER, getOrdersByOrderNumber);
}

function* getCompleteSubscriptionBillWatcher() {
    yield takeLatest(types.GET_COMPLETE_SUBSCRIPTION_BILL, getCompleteSubscriptionBill);
}

function* createDRSubscriptionWatcher() {
    yield takeLatest(types.CREATE_DR_SUBSCRIPTION_PAYMENT, createDRsubscriptionPayment);
}

function* getAvailbleSubscriptionWatcher() {
    yield takeLatest(types.AVAILABLE_SUBSCRIPTION_DETAILS, getAvailbleSubscriptionList);
}
export default function* subscriptionListSaga() {
    yield all([
        getSubscriptionWatcher(),
        getSubscriptionDetailsWatcher(),
        getCompleteSubscriptionBillWatcher(),
        getOrdersByOrderNumberWatcher(),
        deleteSubscriptionServiceWatcher(),
        renewalSubscriptionServiceWatcher(),
        getSubscriptionBillingDetailsWatcher(),
        createDRSubscriptionWatcher(),
        refreshSubscriptionDetailsWatcher(),
        getAvailbleSubscriptionWatcher()
    ]);
}
