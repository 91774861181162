import React, { FC, useContext, useEffect, useState, useCallback } from 'react';
import { Button, Box, Heading, Flex } from '@resideo/blueprint-react';
import ContactForm from '../../../components/cart/shipping/ContactForm';
import ShippingAddressForm from '../../../components/cart/shipping/BillingOrShippingAddressForm';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import CheckoutSteps from '../../../components/cart/CheckoutSteps';
import { cartLinksData } from '../../../config/cartLinks';
import OrderSummaryDesktop from '../../../components/cart/OrderSummaryDesktop';
import ShippingMethod from '../../../components/cart/shipping/ShippingMethod';
import GoBackButton from '../../../components/common/GoBackButton';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import OrderSummaryMobile from '../../../components/cart/OrderSummaryMobile';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/root-reducer';
import {
    getAddressDetails,
    getCreditCardDetails,
    getDeliveryModeDetails,
    setLoading,
    updateShippingInfo,
} from '../../../redux/checkout/action';
import PageLoading from 'components/common/PageLoading';
import PageError from 'components/common/PageError';
import { CartContext } from '../CartProvider';
import { useDispatchPromise } from 'hooks/useDispatchPromise';

export const PageTitle = styled(Heading)`
    text-transform: uppercase;
    padding-bottom: 24px;
    color: ${({ theme }) => theme.colors.textLight3};
    font-family: 'URW_Din_CondBold';
    font-size: 2.813rem;
    letter-spacing: 1.47px;
    @media (max-width: 768px) {
        font-size: 30px;
    }
`;

const Shipping: FC<any> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const [errors, setErrors] = useState({}) as any;
    const { cartActions } = useContext(CartContext);
    const [isLoading, setIsLoading] = useState(false);

    const addressListData = useSelector((state: RootState) => state.checkOutDetails.addressDetails);
    const { fetchingAddressSuccess, fetchingAddressError } = useSelector(
        (state: RootState) => state.checkOutDetails,
    );
    const { deliveryModeDetails }: any = useSelector((state: RootState) => state.checkOutDetails);

    const fetchAddressList = useCallback(async () => {
        await dispatch(getAddressDetails());
    }, []);

    // const fetchDeliveryMode = useCallback(async () => {
    //   await dispatch(getDeliveryModeDetails());
    // }, []);

    useEffect(() => {
        if (!addressListData.length) {
            fetchAddressList();
        }
    }, [dispatch]);

    // useEffect(() => {
    //   if (!addressListData.length && !deliveryModeDetails.length) {
    //     fetchDeliveryMode();
    //   }
    // }, [addressListData]);

    const handleSubmit = async (values) => {
        //dispatch(setLoading(true));
        setIsLoading(true);
        const shipTypeId = values?.shippingMethodValue?.code;
        const shipAddressId = values?.shippingAddress?.id;
        const userInfo = {
            additionalEmail: [values.additionalEmails],
            email: values.email,
            phoneNumber: values.phone,
        };

        hookDispatch(
            updateShippingInfo({
                addressId: shipAddressId,
                deliveryModeId: shipTypeId,
                userInfoPayload: userInfo,
            }),
        )
            .then((response) => {
                //ToDO need to remove
                localStorage.setItem('shippingAddress', JSON.stringify(values.shippingAddress));
                localStorage.setItem('shippingMethod', JSON.stringify(values.shippingMethodValue));
                cartActions.setShippingAddress(values.shippingAddress);
                cartActions.setShippingMethod(values.shippingMethodValue);
                history.push('/cart/payment');
                window.scroll({top:0,behavior:'smooth'})
                //dispatch(setLoading(false));
                setIsLoading(false);
            })
            .catch((error) => {
                console.log('ShippingError', error);
                //dispatch(setLoading(false));
                setIsLoading(false);
            });
    };

    const data = () => ({
        email: '',
        phone: '',
        willReceiveUpdates: false,
        additionalEmails: '',
        shippingMethod: '',
        shippingMethodValue: '',
        promoCode: '',
        multipleAddresses: [],
        billingAddress: {
            companyName: '',
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: 'AL',
            countryCode: 'US',
            zipPostalCode: '',
        },
        shippingAddress: {
            companyName: '',
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
            id: '',
        },
        shippingAddressEdit: {
            companyName: '',
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
    });

    const ShippingSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string().required('Phone number is required'),
        willReceiveUpdates: Yup.boolean(),
        additionalEmails: Yup.string().email('Invalid email'),
        shippingMethod: Yup.string(),
        promoCode: Yup.string(),
        shippingAddress: Yup.object().shape({
            companyName: Yup.string(),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            addressLine1: Yup.string().required('Required'),
            addressLine2: Yup.string(),
            city: Yup.string().required('Required'),
            stateProvinceRegionCode: Yup.string(),
            countryCode: Yup.string(),
            zipPostalCode: Yup.string().required('Required'),
        }),
    });

    return fetchingAddressSuccess && addressListData ? (
        <PageLoading />
    ) : fetchingAddressError ? (
        <PageError />
    ) : (
        <Box data-test-shipping-form>
            {isLoading && <PageLoading />}
            <CheckoutSteps step2 />
            <Flex marginBottom='medium' marginX='large' flexDirection='column'>
                <PageTitle data-test-page-title paddingTop={['medium', 'medium', 'large', 'large']}>
                    {t('cart.shipping.checkout')}
                </PageTitle>
                <Formik
                    initialValues={data()}
                    validationSchema={ShippingSchema}
                    onSubmit={handleSubmit}>
                    {({ touched, values, setFieldValue, errors: fErrors, handleBlur }) => ( 
                        <Form>
                            <Flex paddingBottom={['medium', 'medium', 'medium', '0']}>
                                <OrderSummaryMobile setFieldValue={setFieldValue} values={values} />
                            </Flex>
                            <Flex flexDirection={['column', 'column', 'column', 'row']}>
                                <Box width={['100%', '100%', '100%', '49%']} marginRight='medium'>
                                    <ContactForm
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        fErrors={fErrors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        disableContact
                                    />
                                    <ShippingAddressForm
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        setErrors={setErrors}
                                        errors={errors}
                                        shippingAddressList={addressListData}
                                    />
                                    {deliveryModeDetails && deliveryModeDetails?.length > 0 && (
                                        <ShippingMethod
                                            setFieldValue={setFieldValue}
                                            shippingMethod={deliveryModeDetails}
                                        />
                                    )}
                                </Box>
                                <Box width={['100%', '100%', '100%', '49%']}>
                                    <OrderSummaryDesktop
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                    <Flex
                                        justifyContent='flex-end'
                                        alignItems='center'
                                        paddingTop='large'
                                        flexDirection={[
                                            'column-reverse',
                                            'column-reverse',
                                            'column-reverse',
                                            'row',
                                        ]}>
                                        <GoBackButton
                                            url={`${process.env.PUBLIC_URL ?? ''}${
                                                cartLinksData[0]
                                            }`}
                                            text={t('cart.backToCart')}
                                        />
                                        <Button
                                            data-test-continue-to-payment-btn
                                            disabled={
                                                !values.email ||
                                                !values.phone ||
                                                !values.shippingAddress.firstName
                                            }
                                            type='submit'
                                            marginLeft='medium'
                                            paddingX={['auto', 'auto', 'large', 'xLarge']}
                                            width={['100%', '100%', 'auto', 'auto']}
                                            marginBottom={['large', 'large', 0, 0]}
                                            variant='primary'>
                                            {t('cart.payment.continueToPayment')}
                                        </Button>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Flex>
        </Box>
    );
};

export default Shipping;
