import { types } from './types';

type CreateB2BUserProps = {
    b2BCustomerWsDTO : any;
};

export const createB2BUser = (payload?: CreateB2BUserProps) => ({
    type: types.CREATE_B2B_PRO_USER,
    payload,
});

export const createB2BUserSuccess = (payload: any) => ({
    type: types.CREATE_B2B_PRO_USER_SUCCESS,
    payload,
});

export const createB2BUserFail = (payload: any) => ({
    type: types.CREATE_B2B_PRO_USER_FAIL,
    payload,
});