import React, { useState, useEffect } from 'react';
import { Box, Text, Image, Flex, Button, TextArea, Checkbox } from '@resideo/blueprint-react';
import { CloseRoundGreyImg } from 'assets/images';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getVideoUrl } from 'components/productdetails/Resources';
import SeparatorLine from 'components/common/SeparatorLine';
import {
    cancelSubscriptionService,
    getSubscriptionDetails,
    renewalSubscriptionService,
} from 'redux/subscriptions/actions';
import { useRouteMatch } from 'react-router-dom';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import moment from 'moment';

const CancelBtn = styled(Button)`
    border: 0;
    box-shadow: none;
    :focus,
    :active {
        outline: none;
        box-shadow: none;
    }
`;

const IframeModal = styled.iframe`
    width: 900px;
    height: 500px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const VideoContainer = styled(Flex)`
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
`;

const StyledFooter = styled(Flex)`
    border-top: 1px solid ${({ theme }) => theme.colors.checkBoxBackground} !important;
    width: 100%;
`;

const TextSorry = styled(Text)`
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 16px;
    }
    @media (max-width: 560px) {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
`;

const LabelReason = styled.label`
    font-size: 14px;
    letter-spacing: 0;
`;

const TextAreaReason = styled(TextArea)`
    margin-top: 10px;
`;

const AutoRenewBody = styled.div`
    padding: 38px 71px 80px 80px;
    div {
        span {
            font-weight: bold;
        }
    }
    .warning-label {
        font-weight: 400;
    }
    .Turn-on::after {
        align-content: flex-end;
        justify-content: flex-start;
        color: red;
    }
    @media (max-width: 768px) {
        padding: 16px;
    }
`;

const ModalBody = ({
    autoRenew,
    cancelSub,
    setFieldValue,
    closeModal,
    otherReason,
    program,
    expires,
    setAutoRenew,
    programEnrollmentId,
    modalVideo,
    handleSubscriptionCancel,
    setLoading,
}: any): JSX.Element => {
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const { t } = useTranslation();
    const hookDispatch = useDispatchPromise();
    const modalType = localStorage.getItem('modalType');
    const path = useRouteMatch() as any;
    const enrollmentId = path.params.id;

    const handleAutoRenew = async () => {
        if (enrollmentId !== '' && enrollmentId !== undefined) {
            setLoading(true);
            hookDispatch(
                renewalSubscriptionService({
                    subscriptionId: enrollmentId,
                    renewalType: autoRenew ? 'NON_RENEWING' : 'AUTO_RENEWING',
                }),
            )
                .then((response: any) => {
                    handleManageSubscription();
                    setLoading(false);
                    console.log('FETCHED!');
                })
                .catch((error) => {
                    console.log('Error', error);
                    setLoading(false);
                });
        }
    };

    const handleCancel = async () => {
        let cancellationReason = '';
        if (cancelSub === 'Other') {
            cancellationReason = otherReason;
        } else {
            cancellationReason = cancelSub;
        }
        setLoading(true);
        if (enrollmentId) {
            hookDispatch(
                cancelSubscriptionService({
                    subscriptionId: enrollmentId,
                    cancelReason: cancellationReason,
                    cancelledDate: moment().format('YYYY-MM-DD').toString(),
                }),
            )
                .then((response: any) => {
                    handleManageSubscription();
                })
                .catch((error) => {
                    console.log('Error', error);
                    setLoading(false);
                });
        }
    };

    const handleManageSubscription = async () => {
        if (enrollmentId !== '' && enrollmentId !== undefined) {
            hookDispatch(getSubscriptionDetails({ subscriptionId: enrollmentId }))
                .then((response: any) => {
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error', error);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        if (otherReason !== '' && cancelSub === 'Other') {
            // It must have text in it!
            setSubmitEnabled(true);
        }
        if (otherReason === '' && cancelSub === 'Other') {
            // It must have be disabled without text in it!
            setSubmitEnabled(false);
        }
        if (cancelSub === 'Not the right product for me') {
            setSubmitEnabled(true);
        }
        if (cancelSub === 'Technical Issues') {
            setSubmitEnabled(true);
        }
        if (cancelSub === 'Price') {
            setSubmitEnabled(true);
        }
    }, [cancelSub, otherReason]);

    switch (modalType) {
        case 'AUTO_RENEW':
            return (
                <AutoRenewBody>
                    {/* {autoRenew && (
                        <Text
                            fontSize='30px'
                            fontWeight='500'
                            lineHeight='35px'
                            marginBottom='1.25rem'>
                            {t('manageSubscriptions.planInfo.modal.renew.sorry')}
                        </Text>
                    )} */}
                    <Text fontSize={['18px', '18px', '21px', '21px']} marginBottom='40px' color='#000000' display='inline'>
                        {t('manageSubscriptions.planInfo.modal.renew.your')}
                        {program}
                        {autoRenew ? (
                            <>
                                {t('manageSubscriptions.planInfo.modal.renew.canceledSubscription')}

                                <span>{expires}.</span>
                            </>
                        ) : (
                            t('manageSubscriptions.planInfo.modal.renew.convenientlyRenewed')
                        )}
                        {/* {t('manageSubscriptions.planInfo.modal.renew.your')}
                        {program}
                        {t('manageSubscriptions.planInfo.modal.renew.convenientlyRenewed')} */}
                    </Text>
                    {autoRenew && (
                        <Text fontSize={['18px', '18px', '21px', '21px']} fontWeight='bold' color='#221E1F' paddingTop='7px'>
                            {t('manageSubscriptions.planInfo.modal.renew.warning')}
                            <span className='warning-label'>
                                {t('manageSubscriptions.planInfo.modal.renew.warningLabel')}
                            </span>
                        </Text>
                    )}
                    <SeparatorLine />
                    <Flex justifyContent='flex-end' padding={['small', 'small', 'large', 'large']}>
                        <CancelBtn onClick={closeModal}>
                            {t('manageSubscriptions.planInfo.modal.renew.cancel')}
                        </CancelBtn>
                        <Button
                            variant='primary'
                            onClick={async () => {
                                await handleAutoRenew();
                                closeModal();
                                console.log('Something was fetched!');
                            }}
                            marginLeft='medium'>
                            {t('common.save')}
                        </Button>
                    </Flex>
                </AutoRenewBody>
            );
        case 'CANCEL_SUB':
            return (
                <>
                    <Box
                        marginBottom='medium'
                        paddingX={['0', 'small', 'xLarge', 'xLarge']}
                        paddingY={['small', 'small', 'large', 'large']}>
                        <TextSorry>
                            {t('manageSubscriptions.planInfo.modal.cancelling.sorry')}
                        </TextSorry>
                        <Text fontSize='1.125rem' fontWeight='bold' paddingBottom='1.25rem'>
                            {t('manageSubscriptions.planInfo.modal.cancelling.before')}
                        </Text>
                        <Flex
                            alignItems='center'
                            marginBottom='1rem'
                            onClick={() => {
                                if (cancelSub === 'Not the right product for me') {
                                    setFieldValue('cancelSub', '');
                                } else {
                                    setFieldValue('cancelSub', 'Not the right product for me');
                                }
                            }}>
                            <Checkbox
                                type='checkbox'
                                name='cancelSub'
                                marginRight='0.625rem'
                                checked={cancelSub === 'Not the right product for me'}
                                onChange={() =>
                                    setFieldValue('cancelSub', 'Not the right product for me')
                                }
                            />
                            <label htmlFor='cancelSub'>
                                {t('manageSubscriptions.planInfo.modal.cancelling.notRight')}
                            </label>
                        </Flex>
                        <Flex
                            alignItems='center'
                            marginBottom='1rem'
                            onClick={() => {
                                if (cancelSub === 'Price') {
                                    setFieldValue('cancelSub', '');
                                } else {
                                    setFieldValue('cancelSub', 'Price');
                                }
                            }}>
                            <Checkbox
                                type='checkbox'
                                name='cancelSub'
                                marginRight='0.625rem'
                                checked={cancelSub === 'Price'}
                                onChange={() => setFieldValue('cancelSub', 'Price')}
                            />
                            <label htmlFor='cancelSub'>
                                {t('manageSubscriptions.planInfo.modal.cancelling.price')}
                            </label>
                        </Flex>
                        <Flex
                            alignItems='center'
                            marginBottom='1rem'
                            onClick={() => {
                                if (cancelSub === 'Technical Issues') {
                                    setFieldValue('cancelSub', '');
                                } else {
                                    setFieldValue('cancelSub', 'Technical Issues');
                                }
                            }}>
                            <Checkbox
                                type='checkbox'
                                name='cancelSub'
                                marginRight='0.625rem'
                                checked={cancelSub === 'Technical Issues'}
                                onChange={() => setFieldValue('cancelSub', 'Technical Issues')}
                            />
                            <label htmlFor='cancelSub'>
                                {t('manageSubscriptions.planInfo.modal.cancelling.technical')}
                            </label>
                        </Flex>
                        <Flex
                            alignItems='center'
                            marginBottom='1rem'
                            onClick={() => {
                                if (cancelSub === 'Other') {
                                    setFieldValue('cancelSub', '');
                                } else {
                                    setFieldValue('cancelSub', 'Other');
                                }
                            }}>
                            <Checkbox
                                type='checkbox'
                                name='cancelSub'
                                marginRight='0.625rem'
                                checked={cancelSub === 'Other'}
                                onChange={() => setFieldValue('cancelSub', 'Other')}
                            />
                            <label htmlFor='cancelSub'>
                                {t('manageSubscriptions.planInfo.modal.cancelling.other')}
                            </label>
                        </Flex>
                        {cancelSub === 'Other' && (
                            <Box>
                                <LabelReason>
                                    {t(
                                        'manageSubscriptions.planInfo.modal.cancelling.describeReason',
                                    )}
                                </LabelReason>
                                <TextAreaReason
                                    rows={4}
                                    name='otherReason'
                                    className='black-place-holder'
                                    onChange={(e) => setFieldValue('otherReason', e.target.value)}
                                    value={otherReason}
                                />
                            </Box>
                        )}
                    </Box>
                    <Flex paddingX='large'>
                        <StyledFooter>{/*Separator*/}</StyledFooter>
                    </Flex>
                    <Flex justifyContent='flex-end' padding={['small', 'small', 'large', 'large']}>
                        <Button
                            marginRight='medium'
                            type='submit'
                            variant='secondary'
                            onClick={() => {
                                closeModal();
                            }}>
                            {t('manageSubscriptions.planInfo.modal.cancelling.cancel')}
                        </Button>
                        <Button
                            type='submit'
                            variant='primary'
                            disabled={!submitEnabled}
                            onClick={async () => {
                                await handleCancel();
                                closeModal();
                            }}>
                            {t('manageSubscriptions.planInfo.modal.cancelling.submit')}
                        </Button>
                    </Flex>
                </>
            );
        case 'PROMOTE_VIDEO':
            return (
                <VideoContainer
                    width='100vw'
                    height='100vh'
                    backgroundColor='rgb(0,0,0,0.4);'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    padding='0'>
                    <Flex
                        height='4rem'
                        backgroundColor='primary'
                        maxWidth='964px'
                        width='100%'
                        justifyContent='flex-end'>
                        <Image
                            padding='1rem'
                            onClick={closeModal}
                            src={CloseRoundGreyImg}
                            alt='close'
                        />
                    </Flex>
                    <Flex backgroundColor='#fff' padding='2rem' maxWidth='964px' width='100%'>
                        <IframeModal
                            src={getVideoUrl(modalVideo)}
                            title='YouTube video player'
                            frameBorder={0}
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        />
                    </Flex>
                </VideoContainer>
            );

        default:
            return <></>;
    }
};

export default ModalBody;
