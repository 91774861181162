import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, Flex, Button } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CartItemPreview from './CartItemPreview';
import SeparatorLine from 'components/common/SeparatorLine';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { useHistory } from 'react-router-dom';

const StyledHeading = styled(Heading)`
    text-transform: uppercase;
    font-family: 'URW_Din_CondBold';
`;

const EmptyCartText = styled(Heading)`
    text-transform: uppercase;
    font-family: 'URW_Din_CondBold';
`;

const Container = styled.div`
max-height: 75vh;
overflow-y: auto;
padding-right: 15px;
`;

const Summary = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom; 2rem;
`;

const CheckoutButton = styled(Button)`
  background: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  padding: 1rem 2rem;
  width: 250px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  border-radius:0;
  @media (max-width: 768px) {
    margin-top: 30px;
    width: 120px;
  }
`;

const CartPreview = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const cartId = localStorage.getItem('cartId') ?? '';
    const cartItemList =
        localStorage.getItem('cartItems') &&
            localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];
    const [cartItems, setCartItems] = useState(cartItemList);
    const cartItemData: any = useSelector((state: RootState) => state.cartDetails.cartDetails);

    useEffect(() => {
        setCartItems(cartItemList)
    }, [cartItemData]);

    const handleCheckout = () => {
        history.push('/cart')
        window.scroll({ top: 0, behavior: 'smooth' })
    }
    
    return (
        <Box data-test-cart-preview maxHeight={'80vh'} overflow={'hidden'}>
            {cartItems && !cartItems?.entries?.length ? (
                <>
                    <EmptyCartText textAlign='center' fontSize='2rem' fontFamily='Roboto' letterSpacing='normal'
                        fontWeight='500'>
                        {t('cart.emptyCart')}
                    </EmptyCartText>
                </>
            ) : (
                <Container>
                    <StyledHeading
                        fontSize='1.5rem'
                        color='textHeading'
                        textAlign={'left'}>
                        {t('cart.yourShoppingCart')}
                    </StyledHeading>
                    <Text
                        data-test-cart-ref-number
                        marginBottom='1.5rem'
                        paddingTop='xSmall'
                        color='#767676'
                        fontWeight='700'
                        fontSize='medium'
                        textAlign={'left'}>
                        REF # {cartId}
                    </Text>

                    {cartItems && cartItems?.entries?.map((item, i) => (
                        <Box key={i}>
                            <CartItemPreview
                                item={item}
                            />
                            <SeparatorLine />
                        </Box>
                    ))}

                    <Summary>
                        <Flex
                            paddingY='small'
                            justifyContent={['center', 'center', 'flex-end']}
                            alignItems='center'>
                            <Heading
                                color='cartTextHeading'
                                fontWeight='500'
                                fontSize='1rem'
                                paddingRight='2.8125rem'>
                                {t('cart.subtotalNoTax')}
                            </Heading>
                            <Heading
                                data-test-cart-sub-total
                                fontSize='1rem'
                                color='cartTextHeading'
                                fontWeight='700'>
                                {cartItems?.subTotalWithoutDiscount?.formattedValue}
                            </Heading>
                        </Flex>

                        <Flex
                            paddingY='small'
                            justifyContent={'center'}
                            alignItems='center'>
                            <CheckoutButton
                                onClick={handleCheckout}
                                //  to='/cart'
                                data-test-checkout-btn
                            >
                                {'Checkout'}
                            </CheckoutButton>
                        </Flex>

                    </Summary>

                </Container>
            )}
        </Box>
    );
};

export default CartPreview;
