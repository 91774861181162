import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button, Box, Text, Checkbox, Label, Flex, Heading, Card } from '@resideo/blueprint-react';
import CryptoAES from 'crypto-js/aes';
import TaxExemption from '../../../components/cart/payment/TaxExemption';
import OrderSummaryDesktop from '../../../components/cart/OrderSummaryDesktop';
import OrderSummaryMobile from '../../../components/cart/OrderSummaryMobile';
import CheckoutSteps from '../../../components/cart/CheckoutSteps';
import GoBackButton from '../../../components/common/GoBackButton';
import * as Yup from 'yup';
import { cartLinksData } from '../../../config/cartLinks';
import { useToast } from '../../../components/common/Toast';
import Alert from '../../../components/common/Alert';
import ContactForm from '../../../components/cart/shipping/ContactForm';
import moment from 'moment';
import styled from 'styled-components';
//import Paypal from '../../../components/Paypal';
import { getFullStateByCode } from '../../../config/states';
import PaymentMethodFormBody, {
    ExistingCreditCardItem,
    ExpirationStatus,
    getCCType,
    getFirstNumFromBrand,
    getMonthAndYear,
    initialPaymentBodyValues,
    PaymentMethodOptions,
    validatePaymentMethod,
    validExpDate,
} from '../../../components/payment/PaymentMethodFormBody';
import { processPaymentSources } from '../../../components/Modal/PaymentMethodForm';
import { useSelector, useDispatch } from 'react-redux';
import {
    getCreditCardDetails,
    setLoading,
    createCreditCard,
    updateCreditCard,
    createContactInfo,
} from 'redux/checkout/action';
import { RootState } from '../../../redux/root-reducer';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { CartContext } from '../CartProvider';
import PageLoading from 'components/common/PageLoading';
import PageError from 'components/common/PageError';
import {
    creteDRBillingResponse,
    creteDRNewBilling,
    updateDRPayment,
} from 'redux/digitalriver/action';
import { store } from '../../../redux/store';
import { digitalRiverUrl } from '../../../config/api/url';

const PageTitle = styled(Heading)`
    text-transform: uppercase;
    padding-bottom: 24px;
    color: ${({ theme }) => theme.colors.textLight3};
    font-family: 'URW_Din_CondBold';
    font-size: 2.75rem;
    letter-spacing: 1.47px;
    @media (max-width: 768px) {
        font-size: 30px;
    }
`;

export const PaymentContainer = styled(Card) <{ isError?: boolean }>`
    border: ${({ isError, theme }) =>
        isError ? '2px solid red' : `2px solid ${theme.colors.lightenGray}`};
    border-radius: 0.9375rem;
    padding: 1.5625rem;
`;

const Payment = ({ viewer }) => {
    const toast = useToast();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const creditcardListData = useSelector(
        (state: RootState) => state.checkOutDetails.creditCardDetailsList,
    );
    const { fetchingCreditCardError, fetchingCreditCardSuccess } = useSelector(
        (state: RootState) => state.checkOutDetails,
    );

    const [digitalRiverPayment, setDigitalRiverPayment] = useState() as any;
    const [isPayPalEnabled, setIsPayPalEnabled] = useState(false);
    const [payPalSourceData, setPayPalSourceData] = useState({
        result: {} as any,
        isAuthorize: false,
    });
    const [ifAgreedToAutoRenewalTerms, setIfAgreedToAutoRenewalTerms] = useState(false);
    const [taxExempt, setTaxExempt] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [uploadedImg, setUploadedImg] = useState('');
    const [savedCards, setSavedCards] = useState<ExistingCreditCardItem[]>([]);
    let cartItems =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];
    const hasProduct =
        cartItems &&
        cartItems?.entries &&
        cartItems?.entries?.filter((x) => x.product.itemType?.toLowerCase() === 'product').length >
        0;

    const [, /* taxInfoChanged */ setTaxInfoChanged] = useState(false);
    const [taxInfoComplete, setTaxInfoComplete] = useState(true);
    const [dateValidated, setDateValidated] = useState(true);
    const [cardAlreadySelected, setCardAlreadySelected] = useState();

    const history = useHistory() as any;
    const cartId: any = localStorage.getItem('cartId') ?? '';

    // if (defaultBillTo || isPayPalEnabled) {
    //   // remove warning
    // }

    useEffect(() => {
        if (history.location.state && history.location.state.tax) {
            setTaxExempt(true);
        }
    }, [history.location]);

    useEffect(() => {
        if (creditcardListData && creditcardListData.length > 0) {
            setSavedCards(processPaymentSources(creditcardListData));
        }
    }, [creditcardListData]);

    const fetchCreditCardDetails = async () => {
        await dispatch(getCreditCardDetails());
    };

    useEffect(() => {
        if (!creditcardListData.length) {
            fetchCreditCardDetails();
        }
    }, [dispatch]);

    const partnerAccount = viewer?.partnerUsers?.edges[0]?.node?.partnerAccount;
    const partnerBilling = partnerAccount?.partnerBillings?.edges[0]?.node;
    const paymentSources = savedCards;

    const taxCert = partnerBilling?.taxCertificate;

    const { cartActions } = useContext(CartContext);

    useEffect(() => {
        if (digitalRiverPayment === undefined) {
            // @ts-expect-error because we need to ignore this
            const paymentLib = new DigitalRiver(
                `${process.env.REACT_APP_DIGITAL_RIVER_API_PUBLIC_KEY}`,
            );
            setDigitalRiverPayment(paymentLib);
        }
    }, []);

    useEffect(() => {
        let termsPreviouslyChecked;
        if (sessionStorage.getItem('agreement-terms-checked') === 'true') {
            termsPreviouslyChecked = true;
        } else if (sessionStorage.getItem('agreement-terms-checked') === 'false') {
            termsPreviouslyChecked = false;
        }
        setIfAgreedToAutoRenewalTerms(termsPreviouslyChecked);
    }, []);

    useEffect(() => {
        const { currentlySelectedCard } = history.location.state || {};
        if (currentlySelectedCard) {
            const currentCard = JSON.parse(currentlySelectedCard);
            setCardAlreadySelected(currentCard);
        }
    }, []);

    const setIfAgreedToAutoRenewalTermsFn = () => {
        // if (!ifAgreedToAutoRenewalTerms) {
        //     sessionStorage.setItem('agreement-terms-checked', 'true');
        // } else if (ifAgreedToAutoRenewalTerms) {
        //     sessionStorage.setItem('agreement-terms-checked', 'false');
        // }
        setIfAgreedToAutoRenewalTerms(!ifAgreedToAutoRenewalTerms);
    };

    const sourceData = {
        type: 'creditCard',
        paymentSessionId: '',
        futureUse: true,
        upstreamId: '',
        mandate: {
            terms: t('cart.payment.terms'),
        },
        owner: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            organization: '',
            upstreamId: '',
            address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: 'US',
                postalCode: sessionStorage.getItem('temp-zip-postal-code')
                    ? sessionStorage.getItem('temp-zip-postal-code')
                    : 0,
            },
        },
        creditCard: {
            number: '',
            expirationMonth: 0,
            expirationYear: 0,
            cvv: '',
        },
        amount: 0,
        currency: 'USD',
    };

    const updateDRpayment = async (id) => {
        hookDispatch(updateDRPayment({ selectedPaymentMethodId: id }))
            .then((response) => {
                dispatch(setLoading(false));
                history.push('/cart/review-order');
            })
            .catch((error) => {
                dispatch(setLoading(false));
                toast(
                    <Alert variant='error'>
                        <Text>{t('error.payment')}</Text>
                    </Alert>,
                    {
                        duration: 10000,
                    },
                );
            });
    };

    const createPRresponse = async (drResponse) => {
        const dataToSend = {
            billingAddress: {
                cellphone: drResponse?.owner?.phone ?? '',
                companyName: drResponse?.owner?.organization ?? '',
                country: {
                    isocode: drResponse?.owner?.address?.country,
                    name: drResponse?.owner?.address?.country,
                },
                defaultAddress: false,
                email: drResponse?.owner?.email,
                firstName: drResponse?.owner?.firstName,
                lastName: drResponse?.owner?.lastName,
                line1: drResponse?.owner?.address?.line1,
                line2: drResponse?.owner?.address?.line2 ?? '',
                phone: drResponse?.owner?.address?.phone ?? '',
                postalCode: drResponse?.owner?.address?.postalCode,
                region: {
                    countryIso: drResponse?.owner?.address?.country,
                    isocode:
                        drResponse?.owner?.address?.country +
                        '-' +
                        drResponse?.owner?.address?.state,
                },
                shippingAddress: false,
                town: drResponse?.owner?.address?.city,
                visibleInAddressBook: false,
            },
            drAmount: drResponse?.amount,
            drClientSecret: drResponse?.clientSecret,
            drSourceId: drResponse?.id,
            drSourceType: drResponse?.type,
            drccbrand: drResponse?.creditCard?.brand,
            drccexpiryMonth: drResponse?.creditCard?.expirationMonth,
            drccexpiryYear: drResponse?.creditCard?.expirationYear,
            drcclastFourDigits: drResponse?.creditCard?.lastFourDigits,
            savePaymentInfo: true,
        };

        hookDispatch(creteDRBillingResponse({ dRPaymentDetailsForm: dataToSend }))
            .then((response) => {
                dispatch(setLoading(false));
                history.push('/cart/review-order');
            })
            .catch((error) => {
                console.log('Error', error);
                dispatch(setLoading(false));
            });
    };

    const drNewBilling = async (newBillingAddress, sourceResult) => {
        const [firstName, lastName, ...rest] = newBillingAddress.payment.contactName.split(' ');

        const newAddressData = {
            address: {
                city: newBillingAddress.payment.city,
                country: newBillingAddress.payment.countryCode,
                line1: newBillingAddress.payment.addressLine1,
                line2: newBillingAddress.payment.addressLine2 ?? '',
                postalCode: newBillingAddress.payment.zipPostalCode,
                state: newBillingAddress.payment.stateProvinceRegionCode,
            },
            email: newBillingAddress.email ?? '',
            firstName: firstName,
            lastName: lastName + (rest ? ' ' + rest.join(' ') : ''),
            phoneNumber: '',
        };
        //await dispatch(creteDRNewBilling({ billingAddress: newAddressData }));
        hookDispatch(creteDRNewBilling({ billingAddress: newAddressData }))
            .then((response) => {
                createPRresponse(sourceResult);
            })
            .catch((error) => {
                dispatch(setLoading(false));
                toast(
                    <Alert variant='error'>
                        <Text>{t('error.payment')}</Text>
                    </Alert>,
                    {
                        duration: 10000,
                    },
                );
                console.log('Error', error);
            });
    };

    const drUpdateBillingAddress = async (billingAddress) => {
        const [firstName, lastName, ...rest] = billingAddress.contactName.split(' ');

        const updateBillingAddressData = {
            address: {
                city: billingAddress.city,
                country: billingAddress.countryCode,
                line1: billingAddress.addressLine1,
                line2: billingAddress.addressLine2 ?? '',
                postalCode: billingAddress.zipPostalCode,
                state: billingAddress.stateProvinceRegionCode,
            },
            email: billingAddress.email ?? '',
            firstName: firstName,
            lastName: lastName + (rest ? ' ' + rest.join(' ') : ''),
            phoneNumber: '',
        };

        //await dispatch(creteDRNewBilling({ billingAddress: updateBillingAddressData }));
        hookDispatch(creteDRNewBilling({ billingAddress: updateBillingAddressData }))
            .then((response) => {
                updateDRpayment(billingAddress.paymentSourceId);
            })
            .catch((error) => {
                dispatch(setLoading(false));
                toast(
                    <Alert variant='error'>
                        <Text>{t('error.payment')}</Text>
                    </Alert>,
                    {
                        duration: 10000,
                    },
                );
                console.log('Error', error);
            });
    };

    const validate = (values) => {
        const paymentErrors = validatePaymentMethod(values.payment, t);
        if (paymentErrors) {
            return { payment: paymentErrors };
        }
        return undefined;
    };

    const contactDetails = async (values) => {
        const contactInfo = {
            additionalEmail: [values.additionalEmails],
            email: values.email,
            phoneNumber: values.phone,
        };
        await dispatch(createContactInfo({ contactInfo: contactInfo }));
    };

    const paymentHandler = async (values) => {
        localStorage.setItem(
            'taxExemptionApplication',
            JSON.stringify(values.taxExemptionApplication),
        );
        dispatch(setLoading(true));
        if (!hasProduct) {
            contactDetails(values);
        }
        let noFileOrUploadSuccessful = true;
        if (file) {
            if (!taxInfoComplete) {
                toast(
                    <Alert variant='error'>
                        <Text>Please fill out all required fields for tax exemption</Text>
                    </Alert>,
                    { duration: 10000 },
                );
                dispatch(setLoading(false));
                return;
            }

            // Below Api call is digital River

            // const formData = new FormData();
            // formData.append('purpose', 'tax_document_customer_upload');
            // formData.append('fileName', file.name);
            // formData.append('title', 'My 2022 tax certificate');
            // formData.append('file', file, file.name);
            // const headers = {
            //     Authorization: `Bearer ${process.env.REACT_APP_DIGITAL_RIVER_AUTH_TOKEN}`,
            //     Accept: '*/*',
            // };
            // const response = await fetch('https://api.digitalriver.com/files', {
            //     method: 'POST',
            //     headers,
            //     body: formData,
            // });
            // const data = await response.json();

            // Update tax certificate information in SAP Commerce
            const taxFormData = new FormData();
            taxFormData.append('companyName', values.taxExemptionApplication.companyName);
            taxFormData.append('taxAuthority', values.taxExemptionApplication.taxAuthority);
            taxFormData.append(
                'DRstartDate',
                moment(values.taxExemptionApplication.startDate)
                    .startOf('day')
                    .utc()
                    .format('DD/MM/YYYY'),
            );
            taxFormData.append(
                'DRendDate',
                moment(values.taxExemptionApplication.endDate)
                    .endOf('day')
                    .utc()
                    .format('DD/MM/YYYY'),
            );
            taxFormData.append('file', file, file.name);

            const { authentication } = store.getState();
            const token = authentication.token && authentication.token;
            const headers = {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
            };
            const cartID = localStorage.getItem('cartId') ?? '';
            const url = digitalRiverUrl + `current/carts/${cartID}/tax/uploadFile`;
            const response = await fetch(url, {
                method: 'POST',
                headers,
                body: taxFormData,
            });

            if (!response.ok) {
                toast(
                    <Alert variant='error'>
                        <Text>{t('cart.payment.failedToUpload')}</Text>
                    </Alert>,
                    {
                        duration: 10000,
                    },
                );
                dispatch(setLoading(false));
                return;
            }
        }
        if (!noFileOrUploadSuccessful) {
            dispatch(setLoading(false));
            toast(
                <Alert variant='error'>
                    <Text>{t('cart.payment.failedToUpload')}</Text>
                </Alert>,
                {
                    duration: 10000,
                },
            );
            dispatch(setLoading(false));
            return;
        }
        let sourceResult;
        let billToFirstName;
        let billToLastName;
        const secret = 'pbj834gjd933746';
        const order =
            localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
                ? JSON.parse(localStorage.getItem('cartItems') || '')
                : {};
        let paymentSourceCreateUpdateSuccess = false;

        if (values.payment.paymentMethod === 'existingCC' && values.payment.savedSourceIndex >= 0) {
            // try {
            //     const result = await digitalRiverPayment.retrieveSource(
            //         savedCards[values.payment.savedSourceIndex].paymentSourceId,
            //         savedCards[values.payment.savedSourceIndex].clientSecret,
            //     );

            //     if (result.source) {
            //         sourceResult = result.source;
            //     }
            //     let expDate = '';
            //     if (sourceResult !== null) {
            //         const firstNum = getFirstNumFromBrand(sourceResult?.creditCard?.brand);
            //         const userCC = {
            //             firstNum,
            //             lastFourNums: sourceResult?.creditCard?.lastFourDigits,
            //             exp: `${sourceResult?.creditCard?.expirationMonth}/${sourceResult?.creditCard?.expirationYear}`,
            //             type: getCCType(firstNum).brand,
            //             name: sourceResult?.creditCard?.brand,
            //             image: getCCType(firstNum).icon,
            //             paymentSourceId:
            //                 savedCards[values.payment.savedSourceIndex].paymentSourceId,
            //         };
            //         cartActions.setCreditCard(userCC);
            //         expDate = userCC.exp;
            //         billToFirstName = sourceResult.owner?.firstName;
            //         billToLastName = sourceResult.owner?.lastName;
            //     }
            //     if (validExpDate(expDate) !== ExpirationStatus.VALID) {
            //         toast(
            //             <Alert variant='error'>
            //                 <Text>{t('error.expiredCard')}</Text>
            //             </Alert>,
            //             {
            //                 duration: 10000,
            //             },
            //         );
            //     } else {
            //         paymentSourceCreateUpdateSuccess = true;
            //     }
            // } catch (error: any) {
            //     console.log('error', error);
            //     dispatch(setLoading(false));
            //     toast(
            //         <Alert variant='error'>
            //             <Text>{error.message}</Text>
            //         </Alert>,
            //         { duration: 10000 },
            //     );
            // }

            await drUpdateBillingAddress(savedCards[values.payment.savedSourceIndex]);
        } else if (values.payment.paymentMethod === 'payPal') {
            sourceResult = payPalSourceData.result.source;
            paymentSourceCreateUpdateSuccess = true;
        } else {
            const firstNum = values.payment.cardNumber.charAt(0);
            const squashedNumber =
                values.payment.cardNumber?.replaceAll?.(' ', '')?.replaceAll?.('-', '') ?? '';
            const lastFourNums = squashedNumber.slice(-4);

            const validCCNumber =
                firstNum === '3' || firstNum === '4' || firstNum === '5' || firstNum === '6';

            if (validCCNumber) {
                const encryptCCNum = CryptoAES.encrypt(
                    values.payment.cardNumber,
                    secret,
                ).toString();

                const userCreditCard = {
                    number: encryptCCNum,
                    firstNum,
                    lastFourNums,
                    exp: values.payment.monthYear,
                    cvc: values.payment.security,
                    type: getCCType(firstNum).brand,
                    name: values.payment.contactName,
                    image: getCCType(firstNum).icon,
                };

                //   const isNotDuplicate =
                //   state?.multipleCards.filter(
                //     card =>
                //       card.cvc === values.payment.security &&
                //       card.exp === values.payment.monthYear &&
                //       card.type === values.payment.brand &&
                //       card.name === values.payment.contactName
                //   ).length === 0;

                // if (values.saveCardToAccount && isNotDuplicate) {
                //   cartActions.setMultipleCards(userCreditCard);
                // }
                // cartActions.setCreditCard(userCreditCard);
            }

            sourceData.paymentSessionId = '';
            sourceData.upstreamId = cartId;
            const [firstName, lastName, ...rest] = values.payment.contactName.split(' ');
            billToFirstName = firstName;
            billToLastName = lastName + (rest ? ' ' + rest.join(' ') : '');
            sourceData.owner = {
                firstName: billToFirstName, // order.billTo?.firstName,
                lastName: billToLastName, // order.billTo?.lastName,
                email: values.email ?? '',
                phone: values.phone ?? '',
                organization: values.payment.companyName,
                upstreamId: cartId,
                address: {
                    line1: values.payment.addressLine1, // order.billTo?.address?.addressLine1,
                    line2: values.payment.addressLine2 ?? ' ', // order.billTo?.address?.addressLine1,
                    city: values.payment.city, // order.billTo?.address?.city,
                    state: values.payment.stateProvinceRegionCode, // order.billTo?.address?.stateProvinceRegionCode,
                    country: values.payment.countryCode, // order.billTo?.address?.countryCode,
                    postalCode: values.payment.zipPostalCode, // order.billTo?.address?.zipPostalCode,
                },
            };
            if (values.payment.contactName.trim() !== '') {
                const nameParts = values.payment.contactName.split(' ');
                if (nameParts.length === 1) {
                    sourceData.owner.organization = nameParts[0];
                } else {
                    sourceData.owner.firstName = nameParts[0];
                    sourceData.owner.lastName = nameParts[1];
                }
            }

            sourceData.creditCard = {
                number: values.payment.cardNumber,
                ...getMonthAndYear(values.payment.monthYear),
                cvv: values.payment.security,
            };

            if (order.totalPriceWithTax === undefined) {
                toast(
                    <Alert variant='error'>
                        <Text>{`An error occurred creating order. Total cost is not defined.`}</Text>
                    </Alert>,
                    { duration: 10000 },
                );
            }

            sourceData.amount = order?.totalPriceWithTax?.value;

            sourceResult = await digitalRiverPayment
                .createSource(sourceData)
                .then(async (result) => {
                    if (result.error) {
                        dispatch(setLoading(false));
                        toast(
                            <Alert variant='error'>
                                <Text> Please enter all required fields correctly.</Text>
                                {/* <Text>{result.error.type}:</Text>
                                    {result.error.errors.map(err => {
                                    return (
                                        <>
                                        <Text>code: {err.code}</Text>
                                        <Text>mesg: {err.message}</Text>
                                        </>
                                    );
                                    })} */}
                                {/* <Text>Please enter a valid zipcode.</Text> */}
                            </Alert>,
                            { duration: 10000 },
                        );
                        return null;
                    } else {
                        const source = result.source;
                        paymentSourceCreateUpdateSuccess = true;
                        return source;
                    }
                })
                .catch((err: any) => {
                    dispatch(setLoading(false));
                    console.log('error', err);
                    toast(
                        <Alert variant='error'>
                            <Text>{err.message}</Text>
                        </Alert>,
                        { duration: 10000 },
                    );
                });
        }
        if (paymentSourceCreateUpdateSuccess) {
            if (values.payment.paymentMethod === 'payPal') {
            } else {
                await drNewBilling(values, sourceResult);
            }
        }
    };

    const fromReviewPage = history.location.state && history.location.state.tax;

    const initialPaymentValues = {
        paymentMethod: '' as PaymentMethodOptions,
        savedSourceIndex: -1,
    };

    const currentCardIndex = savedCards.findIndex(
        (x) => x.paymentSourceId === paymentSources[0]?.paymentSourceId,
    );

    if (currentCardIndex >= 0) {
        initialPaymentValues.paymentMethod = 'existingCC';
        initialPaymentValues.savedSourceIndex = currentCardIndex;
    }

    const data = () => ({
        email: '',
        phone: '',
        willReceiveUpdates: false,
        additionalEmails: '',
        shippingMethod: '',
        shippingAddress: {
            companyName: '',
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        shippingAddressEdit: {
            companyName: '',
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        multipleAddresses: [],
        saveCardToAccount: false,
        defaultBillTo: {
            companyName: '',
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        autoRenewal: sessionStorage.getItem('agreement-terms-checked') === 'true' ? true : false,
        promoCode: '',
        taxExemptionApplication: {
            companyName: fromReviewPage
                ? fromReviewPage.companyName
                : sessionStorage.getItem('temp-company-name') === null ||
                    sessionStorage.getItem('temp-company-name') === ''
                    ? ''
                    : sessionStorage.getItem('temp-company-name'),
            taxAuthority: fromReviewPage
                ? fromReviewPage.taxAuthority
                : sessionStorage.getItem('temp-tax-state') === null ||
                    sessionStorage.getItem('temp-tax-state') === ''
                    ? ''
                    : sessionStorage.getItem('temp-tax-state'),
            startDate: fromReviewPage
                ? moment.utc(fromReviewPage.startDate).format('YYYY-MM-DD')
                : taxCert?.startDate
                    ? moment.utc(taxCert.startDate).format('YYYY-MM-DD')
                    : sessionStorage.getItem('temp-tax-start-date'),
            endDate: fromReviewPage
                ? moment.utc(fromReviewPage.endDate).format('YYYY-MM-DD')
                : taxCert?.endDate
                    ? moment.utc(taxCert.endDate).subtract(1, 'second').format('YYYY-MM-DD')
                    : sessionStorage.getItem('temp-tax-end-date'),
        },
        taxCertificate: '',
        radio: '',
        // use the paymentMethodBody composable formik values
        payment: { ...initialPaymentBodyValues(initialPaymentValues) },
    });

    const disableSubmitButton = (formik) => {
        return (
            !ifAgreedToAutoRenewalTerms ||
            !taxInfoComplete ||
            !dateValidated ||
            !hasProduct && !formik.values.email ||
            !hasProduct && !formik.values.phone ||
            //state.isFetching ||
            formik.errors?.payment
        );
    };

    const ContactInfoSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string().required('Phone number is required'),
        additionalEmails: Yup.string().email('Invalid email'),
    });

    return fetchingCreditCardSuccess && creditcardListData ? (
        <PageLoading />
    ) : fetchingCreditCardError ? (
        <PageError />
    ) : (
        <>
            <CheckoutSteps step3 />
            <Box
                data-test-payment-page
                marginBottom='medium'
                marginX={['medium', 'medium', 'large', 'large']}>
                <PageTitle
                    data-test-payment-page-title
                    paddingTop={['medium', 'medium', 'large', 'large']}>
                    {t('cart.shipping.checkout')}
                </PageTitle>
                <Formik
                    initialValues={data()}
                    validate={validate}
                    validationSchema={!hasProduct && ContactInfoSchema}
                    onSubmit={paymentHandler}
                    enableReinitialize={false}>
                    {(formik) => (
                        <Form>
                            <OrderSummaryMobile
                                values={formik.values}
                                setFieldValue={formik.setFieldValue}
                            />
                            <Flex
                                justifyContent='space-between'
                                width='100%'
                                flexDirection={['column', 'column', 'column', 'row']}>
                                <Box width={['100%', '100%', '100%', '49%']} marginBottom='medium'>
                                    {!hasProduct && (
                                        <>
                                            <Text
                                                data-test-section-title
                                                paddingBottom='medium'
                                                fontWeight='bold'
                                                fontSize='1.313rem'>
                                                {t('cart.orderComplete.contactInformation')}
                                            </Text>
                                            <ContactForm
                                                values={formik.values}
                                                setFieldValue={formik.setFieldValue}
                                                fErrors={formik.errors}
                                                touched={formik.touched}
                                                handleBlur={formik.handleBlur}
                                                disableContact
                                            />
                                        </>
                                    )}
                                    <Text
                                        data-test-section-title
                                        paddingY='medium'
                                        fontWeight='bold'
                                        fontSize='1.313rem'>
                                        {t('cart.payment.paymentMethods')}
                                    </Text>
                                    <Box data-test-payment-sections>
                                        <PaymentContainer>
                                            <PaymentMethodFormBody
                                                setIsNewCardSelected={false}
                                                savedCreditCardSources={savedCards}
                                                drJsLibrary={digitalRiverPayment}
                                                formik={formik}
                                                agreementSelected={false}
                                                initialCreditCard={cardAlreadySelected}
                                                config={{
                                                    valuesProperty: 'payment',
                                                    withSavedCards: true,
                                                    withPayPal: false,
                                                    hideSavedCardsWhenNotActive: true,
                                                    withMakePrimary: true,
                                                    withRenewalTerms: false,
                                                }}
                                            />
                                        </PaymentContainer>
                                    </Box>
                                    <TaxExemption
                                        data-test-payment-sections
                                        values={formik.values}
                                        setFieldValue={formik.setFieldValue}
                                        errors={formik.errors}
                                        taxExempt={taxExempt}
                                        setTaxExempt={setTaxExempt}
                                        uploadedImg={uploadedImg}
                                        setUploadedImg={setUploadedImg}
                                        setFile={setFile}
                                        setTaxInfoChanged={setTaxInfoChanged}
                                        setTaxInfoComplete={setTaxInfoComplete}
                                        dateValidated={setDateValidated}
                                    />
                                </Box>
                                <Box
                                    width={['100%', '100%', '100%', '49%']}
                                    marginBottom='medium'
                                    paddingTop='2.75rem'>
                                    <OrderSummaryDesktop
                                        setFieldValue={formik.setFieldValue}
                                        values={formik.values}
                                    />
                                    <Box padding='medium'>
                                        <Text
                                            color='header.secondaryLinkActive'
                                            fontSize='small'
                                            lineHeight='21px'>
                                            {t('cart.payment.completePurchaseText')}
                                        </Text>
                                        <Text
                                            color='header.secondaryLinkActive'
                                            fontSize='small'
                                            paddingTop='large'
                                            lineHeight='21px'>
                                            {t('cart.payment.iAgreeText')}
                                        </Text>
                                    </Box>

                                    <Box padding='medium'>
                                        <Label>
                                            <Checkbox
                                                name='autoRenewal'
                                                type='checkbox'
                                                marginRight={['medium', 'small']}
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'autoRenewal',
                                                        e.target.checked,
                                                    )
                                                }
                                                disabled={false}
                                                checked={ifAgreedToAutoRenewalTerms}
                                                onClick={() => setIfAgreedToAutoRenewalTermsFn()}
                                            />
                                            <Text
                                                color={'textHeading'}
                                                fontSize='small'
                                                fontWeight='normal'
                                                fontStyle='italic'>
                                                {t('cart.payment.autoRenewalText')}
                                            </Text>
                                        </Label>
                                    </Box>

                                    <Flex
                                        justifyContent='flex-end'
                                        alignItems='center'
                                        marginBottom='medium'
                                        flexDirection={[
                                            'column-reverse',
                                            'column-reverse',
                                            'column-reverse',
                                            'row',
                                        ]}>
                                        <Box paddingTop={['medium', 'medium', 'medium', '0']}>
                                            <GoBackButton
                                                url={`${process.env.PUBLIC_URL ?? ''}${hasProduct ? cartLinksData[1] : cartLinksData[0]
                                                    }`}
                                                text={
                                                    hasProduct
                                                        ? t('cart.shipping.backToShipping')
                                                        : t('cart.backToCart')
                                                }
                                            />
                                        </Box>

                                        {/* {formik.values.payment.paymentMethod === 'payPal' &&
                                            !payPalSourceData.isAuthorize &&
                                            ifAgreedToAutoRenewalTerms ? (
                                            <Paypal
                                                drJsLibrary={digitalRiverPayment}
                                                viewer={viewer}
                                                partnerAccount={partnerAccount}
                                                setIsPayPalEnabled={setIsPayPalEnabled}
                                                setPayPalSourceData={setPayPalSourceData}
                                                values={formik.values.payment}
                                            />
                                            ) : ( */}
                                        <Button
                                            data-test-go-to-review-order
                                            disabled={disableSubmitButton(formik)}
                                            onClick={() => {
                                                setTaxExempt(false);
                                            }}
                                            type='submit'
                                            width={['100%', 'auto', 'auto', 'auto']}
                                            variant='primary'
                                            marginLeft='medium'>
                                            {t('cart.reviewOrder.reviewOrder')}
                                        </Button>
                                        {/* )} */}
                                    </Flex>
                                </Box>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default Payment;
