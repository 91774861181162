import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Heading, Text, Flex, Button, Image, Link } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CartItem from '../../../components/cart/CartItem';
import AddPromoCode from '../../../components/common/AddPromoCode';
import CheckoutSteps from '../../../components/cart/CheckoutSteps';
import GoBackButton from '../../../components/common/GoBackButton';
import { convertMoneyAmount } from '.';
import SeparatorLine from '../../../components/common/SeparatorLine';
import { Form, Formik } from 'formik';
import Alert from '../../../components/common/Alert';
import { whiteWarningIcon } from '../../../assets/images';
import GoForwardButton from '../../../components/common/GoForwardButton';
import CartItemData from '../../../data/cartItems.json';

const StyledHeading = styled(Heading)`
    text-transform: uppercase;
    font-family: 'URW_Din_CondBold';
`;

const CartEmptyBack = styled.div`
    margin-top: 20px;
`;

const ErrorBox = styled(Box)`
    display: flex;
    padding: 1.875rem;
    align-items: center;
`;

enum Status {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    EXPIRED = 'EXPIRED',
    NOT_STARTED = 'NOT_STARTED',
    PENDING = 'PENDING',
    WITHDRAWN = 'WITHDRAWN',
}

const programEnrollments = {
    hab: 'Homeowner App Branding',
};

const Cart: FC<any> = ({
    userPlans,
    digitalRiverBillingId,
    partnerAccountId,
    createCheckoutMutation,
    deleteCheckoutMutation,
    showOnboardingState,
}): JSX.Element => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();
    const state = CartItemData;
    const [hasEnrollmentAlready, setHasEnrollmentAlready] = useState(false);

    const hasProduct = state?.cartItems?.filter((f) => f.type === 'product').length > 0;

    localStorage.setItem('cart', JSON.stringify(state.cartItems));
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const localCart =
            localStorage.getItem('cart') && localStorage.getItem('cart') !== 'undefined'
                ? JSON.parse(localStorage.getItem('cart') || '')
                : [];
        if (params.sku && params.id && params.prc) {
            const cartItem = {
                name: params.name,
                orderPrice: parseInt(params.prc),
                quantity: 1,
                sku: params.sku,
                type: params.type,
                id: params.id,
            };
            localCart.push(cartItem);
            localStorage.setItem('cart', JSON.stringify(localCart));
            window.location.href = `${path}/cart`;
        }
        userPlans.map((obj) => {
            if (
                Status[obj.node.status] === Status.ACTIVE &&
                obj.node.partnerProgram.name === programEnrollments.hab &&
                state?.cartItems?.some((obj) => obj.type === 'HAB')
            ) {
                setHasEnrollmentAlready(true);
            }
        });
    }, []);

    let total = 0;

    const subTotal = {
        amount: `${total}`,
        currencyCode: 'USD',
    };

    const handleSubmit = () => {
        console.log('submitted');
        history.push(`${path}/payment`);
    };
    const hasHAB = state?.order?.lineItems?.filter((f) => f.type === 'HAB').length > 0;

    const isDevelopEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';

    return (
        <Box data-test-cart>
            <CheckoutSteps step1 />
            <Box marginBottom='medium' marginX='large'>
                {state.cartItems.length < 1 ? (
                    <>
                        <StyledHeading
                            color='textHeading'
                            paddingTop={['medium', 'medium', 'large', 'large']}
                            paddingBottom='xSmall'
                            fontSize='2.813rem'
                            textAlign={['left', 'left', 'left', 'left']}>
                            {t('cart.emptyCart')}
                        </StyledHeading>
                        <CartEmptyBack>
                            <GoForwardButton
                                data-test-cart-continue-btn
                                url={`${localStorage.getItem('fromPDP')}`}
                                text='Continue Shopping'
                            />
                        </CartEmptyBack>
                    </>
                ) : (
                    <>
                        <StyledHeading
                            paddingTop={['medium', 'medium', 'large', 'large']}
                            paddingBottom='xSmall'
                            fontSize='2.25rem'
                            color='textHeading'
                            textAlign={['center', 'center', 'left', 'left']}>
                            {t('cart.yourShoppingCart')}
                        </StyledHeading>
                        <Text
                            marginBottom='1.313rem'
                            data-test-cart-ref-number
                            paddingTop='xSmall'
                            color='gray'
                            fontWeight='500'
                            fontSize='medium'
                            textAlign={['center', 'center', 'left', 'left']}>
                            REF # 3430662239
                        </Text>
                    </>
                )}
                {hasEnrollmentAlready && (
                    <ErrorBox backgroundColor='redAlertError'>
                        <Image
                            src={whiteWarningIcon}
                            minHeight='0.875rem'
                            minWidth='0.875rem'
                            alt='error'
                        />
                        <Text
                            marginLeft='0.625rem'
                            alignItems='center'
                            color='white'
                            fontWeight='bold'
                            fontSize='0.875rem'>
                            {t('cart.hasExistingServiceError')}
                        </Text>
                    </ErrorBox>
                )}
                {!showOnboardingState && (
                    <ErrorBox backgroundColor='redAlertError'>
                        <Image
                            src={whiteWarningIcon}
                            minHeight='0.875rem'
                            minWidth='0.875rem'
                            alt='error'
                        />
                        <Text
                            marginLeft='0.625rem'
                            alignItems='center'
                            color='white'
                            fontWeight='bold'
                            fontSize='0.875rem'>
                            {t('cart.completeProfileError1')}
                            <Link
                                paddingX='0.3rem'
                                fontSize='0.875rem'
                                color='header.primaryLinkActive'
                                href={
                                    isDevelopEnvironment
                                        ? 'https://develop--resideo-pro.netlify.app/onboarding/profile'
                                        : `${window.location.origin}/onboarding/profile`
                                }>
                                {t('cart.completeProfileError2')}
                            </Link>
                            {t('cart.completeProfileError3')}
                        </Text>
                    </ErrorBox>
                )}
                {state.cartItems.length < 1 ? (
                    <Box height='500px' />
                ) : (
                    <Formik initialValues={{ promoCode: '' }} onSubmit={handleSubmit}>
                        {({ setFieldValue }) => (
                            <Form>
                                <Box paddingTop={['large', 'xLarge', 'xLarge', 'xLarge']}>
                                    {state.cartItems.length < 1 ? (
                                        <Heading fontSize='xLarge' textAlign='center' color='gray'>
                                            {t('cart.emptyCart')}
                                        </Heading>
                                    ) : (
                                        state.cartItems.map((item, i) => (
                                            <Box key={i}>
                                                <CartItem
                                                    setHasEnrollmentAlready={
                                                        setHasEnrollmentAlready
                                                    }
                                                    isAlert={
                                                        hasEnrollmentAlready && item.type == 'HAB'
                                                    }
                                                    item={item}
                                                />
                                                <Box paddingTop='small'>{/* seprator */}</Box>
                                                <SeparatorLine />
                                            </Box>
                                        ))
                                    )}
                                </Box>
                                <Flex
                                    flexDirection={['column', 'column', 'row', 'row']}
                                    alignItems='center'
                                    justifyContent='space-between'>
                                    <AddPromoCode setFieldValue={setFieldValue} />
                                    <Flex
                                        paddingY='medium'
                                        justifyContent={['center', 'center', 'flex-end']}
                                        alignItems='center'>
                                        <Heading
                                            color='cartTextHeading'
                                            fontWeight='500'
                                            fontSize='1rem'
                                            paddingRight='2.8125rem'>
                                            {t('cart.subtotalNoTax')}
                                        </Heading>
                                        <Heading
                                            data-test-cart-sub-total
                                            fontSize='1rem'
                                            color='cartTextHeading'
                                            fontWeight='700'>
                                            {!hasProduct && !hasHAB
                                                ? '$0.00'
                                                : convertMoneyAmount(subTotal, 'en-us')}
                                        </Heading>
                                    </Flex>
                                </Flex>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    flexDirection={[
                                        'column-reverse',
                                        'column-reverse',
                                        'row',
                                        'row',
                                    ]}
                                    paddingY={['medium', 'medium', 'large', 'large']}>
                                    <GoBackButton
                                        data-test-cart-continue-btn
                                        url={`${localStorage.getItem('fromPDP')}`}
                                        text='Continue Shopping'
                                    />
                                    <Button
                                        data-test-cart-continue-btn
                                        disabled={
                                            hasEnrollmentAlready ||
                                            showOnboardingState ||
                                            state.cartItems.length === 0 ||
                                            state.isFetching
                                        }
                                        variant='primary'
                                        paddingX={['auto', 'auto', 'large', 'xLarge']}
                                        width={['100%', '100%', 'auto', 'auto']}
                                        marginBottom={['large', 'large', 0, 0]}
                                        type='submit'>
                                        {t('continue')}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </Box>
    );
};

export default Cart;
