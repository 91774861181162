import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Box, Text, Label, Heading, Input } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SeparatorLine from '../../common/SeparatorLine';
import CustomLabel from '../../common/CustomLabel';
import { maskPhoneNumber } from '../../../utils/maskPhoneNumber';
import { getConsentsDetails, createConsents, deleteConsents } from '../../../redux/checkout/action';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import moment from 'moment';
import { getUser } from 'redux/graphql/authentication/actions';

const Container = styled(Box)`
    border-radius: 0.9375rem;
    border: 2px solid ${({ theme }) => theme.colors.lightenGray};
    select,
    input {
        border: 2px solid ${({ theme }) => theme.colors.primary};
        font-family: 'Roboto';
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    }
`;

const ModifiedCheckbox = styled(Checkbox)`
    @media (min-width: ${({ theme }) => theme.breakpoints.xSmall}) {
        margin-right: 2.75rem;
    }
`;

const ContactForm: FC<any> = ({
    values,
    setFieldValue,
    fErrors,
    disableContact = false,
    touched,
    handleBlur,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const consentList: any = useSelector(
        (state: RootState) => state.checkOutDetails.fetchingConsentList,
    );

    const userInfo: any = useSelector((state: RootState) => state.authentication.userDetails)

    useEffect(() => {
        setFieldValue('email', userInfo?.contactEmail);
        setFieldValue(
            'phone',
            userInfo &&
              (userInfo?.primaryPhoneNumber ?? userInfo?.phoneNumbers?.[0].number)
          );
    }, [setFieldValue, userInfo]);

    useEffect(() => {
        //getContactInfo();
        getConsentsList();
    }, []);

    useEffect(() => {
        if (
            consentList &&
            consentList[0]?.currentConsent &&
            consentList[0]?.currentConsent?.consentGivenDate
        ) {
            if (moment(consentList[0]?.currentConsent?.consentGivenDate, 'YYYY/MM/DD').isBefore(moment(consentList[0]?.currentConsent?.consentWithdrawnDate, 'YYYY/MM/DD'))) {
                setFieldValue('willReceiveUpdates', false);
            } else {
                setFieldValue('willReceiveUpdates', true);
            }
        }
    }, [consentList]);

    const createOrDeleteConsents = async () => {
        if (consentList?.length > 0) {
            if (!values.willReceiveUpdates) {
                dispatch(createConsents({ templateId: consentList[0]?.id, version: consentList[0]?.version }));
            } else {
                dispatch(deleteConsents({ consentCode: consentList[0]?.currentConsent?.code }));
            }
        }
    }

    const getContactInfo = async () => {
        await dispatch(getUser());
    };

    const getConsentsList = async () => {
        await dispatch(getConsentsDetails());
    };

    return (
        <Container data-test-shipping-form-sections padding='1.5625rem'>
            <Text fontSize='small' paddingBottom='small' fontStyle={'italic'}>
                {t('common.requiredFields')}
            </Text>
            <Box data-test-shipping-email marginBottom='medium'>
                <CustomLabel htmlFor='email' title={`${t('cart.shipping.email')}*`} />
                <Input
                    autoComplete='off'
                    color={'header.secondaryLinkActive'}
                    placeholder='Email'
                    disabled={disableContact}
                    name='email'
                    value={values.email || ''}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    onBlur={handleBlur}
                />
                <Text color='red' fontSize='small' paddingLeft='small'>
                    {touched.email && fErrors && fErrors.email}
                </Text>
            </Box>
            <Box data-test-shipping-phone marginBottom='medium'>
                <CustomLabel htmlFor='phone' title={`${t('cart.shipping.phoneNumber')}*`} />
                <Input
                    autoComplete='off'
                    color={'header.secondaryLinkActive'}
                    placeholder='Phone'
                    disabled={disableContact}
                    name='phone'
                    value={maskPhoneNumber(
                        values.phoneDisplay || values.phone || '',
                        'ContactForm',
                    )}
                    onChange={(e) => {
                        let mob = e.target.value.replace('-', '');
                        if (mob.length > 3) {
                            mob = mob.slice(0, 3) + '-' + mob.slice(3);
                            if (mob.length > 8 && mob[7] !== '-') {
                                mob = mob.slice(0, 7) + '-' + mob.slice(7);
                            }
                        }
                        setFieldValue('phoneDisplay', mob);
                        setFieldValue('phone', e.target.value);
                    }}
                    onBlur={handleBlur}
                />
                <Text color='red' fontSize='small' paddingLeft='small'>
                    {touched.phone && fErrors && fErrors.phone}
                </Text>
            </Box>
            <Box
                data-test-shipping-checkbox
                display='flex'
                marginBottom='large'
                alignItems='center'>
                <Label minWidth={'unset'}>
                    <ModifiedCheckbox
                        name='notified'
                        marginRight='0.625rem'
                        checked={values.willReceiveUpdates}
                        onChange={() => {
                            createOrDeleteConsents();
                            setFieldValue('willReceiveUpdates', !values.willReceiveUpdates);
                        }}
                    />
                </Label>
                <Text fontSize='small' fontStyle='italic' color='textHeading'>
                    {t('cart.shipping.subscribeText')}
                </Text>
            </Box>
            <SeparatorLine />
            <Heading letterSpacing='normal' color={'#221E1F'}>
                {t('cart.shipping.addRecipients')}
            </Heading>
            <Box data-test-additional-emails marginBottom='medium' paddingTop='1.5rem'>
                <CustomLabel htmlFor='emailOpt' title={`${t('cart.shipping.email')}`} />
                <Input
                    autoComplete='off'
                    placeholder='Additional emails'
                    name='additionalEmails'
                    value={values.additionalEmails || ''}
                    onChange={(e) => setFieldValue('additionalEmails', e.target.value)}
                    color={'header.secondaryLinkActive'}
                />
                <Text color='red' fontSize='small' paddingLeft='small'>
                    {values.additionalEmails !== '' && fErrors && fErrors.additionalEmails}
                </Text>
            </Box>
        </Container>
    );
};

export default ContactForm;
