import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { ReactComponent as SearchIcon } from 'components/icons/search-icon.svg';
import { Button, Flex, Text } from '@resideo/blueprint-react';
// import { SuccessAlert, ErrorAlert } from 'components/common/Alert';
import styled from 'styled-components';
import { useToast } from 'components/common/Toast';
import { useIsMounted } from 'hooks/useIsMounted';

const StyledMenuButton = styled(MenuButton)`
  cursor: pointer;
  transform: scale(.77);
`;

const NotificationRowComponent = ({ node, fetch }) => {
  const { t } = useTranslation();
  const toast = useToast();


  return (
    <MenuItem
      onSelect={() => {
        // acceptInvite();
      }}>
      <Flex
        justifyContent='space-between'
        flexDirection={['column', 'row']}
        data-test-invite-notification>
        <Flex
          flexDirection='column'
          marginRight='large'
          alignItems='self-start'
          paddingBottom={['medium', 0]}>
          <Text color='white'>
            {t('header.notifications.invitedTo')}
            {` ${node.partnerAccount.name}`}
          </Text>
          <Text color='faded' fontSize='small'>
            {t('header.notifications.invitedBy')}
          </Text>
        </Flex>
        <Button>{t('header.notifications.acceptInvite')}</Button>
      </Flex>
    </MenuItem>
  );
};

const CustomMenuList = styled(MenuList)`
  position: fixed;
  right: 0;
  top: 50px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    right: 70px;
    top: 50px;
    width: auto;
  }
`;

const useMountEffect = (cb: () => void) => useEffect(cb, []);

const Notifications: FC = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState();

  const searchIconClicked = () => {
      console.log(`searchIconClicked`)
  }

  return (
    <Flex marginRight={['small', 'small', 'medium', 'medium']} data-test-notifications-menu>
      <Menu>
        <StyledMenuButton onClick={searchIconClicked}>
            <SearchIcon />
        </StyledMenuButton>
          <CustomMenuList portal={false}>
            <Text marginX='large' color='white' data-test-no-notifications>
              {t('We will code this during Sprint 32')}
            </Text>
          </CustomMenuList>
      </Menu>
    </Flex>
  );
};

export default Notifications;
